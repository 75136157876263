import { QuestCreateDto, QuestDto, TagCreateDto, TagDto } from "../dtos";
import { getToken, onResponse } from "./token";

function get(url: string) {
  return fetch(process.env.REACT_APP_API_URL + url, {
    headers: {
      "X-Auth": getToken(),
    },
  }).then(onResponse);
}

function post(url: string, body: object) {
  return fetch(process.env.REACT_APP_API_URL + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Auth": getToken(),
    },
    body: JSON.stringify(body),
  }).then(onResponse);
}

export const getTasks = async () => {
  return get("/tasks") as Promise<QuestDto[]>;
};

export const getTasksUndoneAndDoneThisWeek = async () => {
  return get("/tasks-undone-and-done-this-week") as Promise<QuestDto[]>;
};

export const getTaskById = async (id: string) => {
  return get(`/task/${id}`) as Promise<QuestDto>;
};

export const toggleTask = async (taskId: string) => {
  return get("/toggle-task/" + taskId);
};

export const markTaskAsDone = async (taskId: string) => {
  return get("/mark-done/" + taskId);
};

export const markTaskAsUndone = async (taskId: string) => {
  return get("/mark-undone/" + taskId);
};

export const toggleReady = async (taskId: string) => {
  return get("/toggle-ready/" + taskId);
};

export const toggleImportant = async (taskId: string) => {
  return get("/toggle-important/" + taskId);
};

export const deleteTask = async (taskId: string) => {
  return get("/delete-task/" + taskId);
};

export const createTask = async (task: QuestCreateDto) => {
  return post("/create-task", task);
};

export const updateTask = async (taskId: string, task: QuestCreateDto) => {
  return post("/update-task/" + taskId, task);
};

export const getTags = async () => {
  return get("/tags") as Promise<TagDto[]>;
};

export const createTag = async (tag: TagCreateDto) => {
  return post("/create-tag", tag);
};

export const updateTag = async (tag: TagDto) => {
  return post("/update-tag", tag);
};
