import { FC } from "react";
import { Box, Button, Card, Spinner, Text } from "grommet";
import { CommandQueueItemDto } from "../dtos";
import { useCommandQueue } from "../util/command-queue";
import { msToSeconds, useSeconds } from "../../common/util/react";
import { useCreateTaskWithQueue } from "../util/queries";
import { Close, PowerCycle } from "grommet-icons";

export const CommandQueue: FC = () => {
  const { queue, removeFromQueue } = useCommandQueue();
  const [createTask] = useCreateTaskWithQueue();
  const seconds = useSeconds();

  const getItemAge = (item: CommandQueueItemDto) => {
    return seconds - msToSeconds(item.queuedTime);
  };

  const retryCreateTask = async (item: CommandQueueItemDto) => {
    removeFromQueue(item.queueId);
    createTask(item.task);
  };

  const cancelCreateTask = async (item: CommandQueueItemDto) => {
    if (window.confirm(`Usunąć z kolejki ${item.task.name}?`)) {
      removeFromQueue(item.queueId);
    }
  };

  return (
    <Box
      style={{ position: "fixed", bottom: "100px", right: "10px" }}
      gap="small"
      align="right"
    >
      {queue.map((item) => (
        <QueueItem
          item={item}
          key={item.queueId}
          onRetry={() => retryCreateTask(item)}
          onRemove={() => cancelCreateTask(item)}
          age={getItemAge(item)}
        />
      ))}
    </Box>
  );
};

interface QueueItemProps {
  item: CommandQueueItemDto;
  age: number;
  onRetry: () => void;
  onRemove: () => void;
}

const QueueItem: FC<QueueItemProps> = ({ item, age, onRetry, onRemove }) => {
  const { task, status } = item;
  const pending = status === "pending" && age < 10;

  return (
    <Card
      background="white"
      pad="medium"
      gap="medium"
      direction="row"
      align="center"
    >
      {pending ? (
        <Spinner size="small" color="blue" />
      ) : (
        <Button
          icon={<PowerCycle size="small" color="red" />}
          onClick={onRetry}
        />
      )}
      <Text>{task.name}</Text>
      {!pending && <Button icon={<Close size="small" />} onClick={onRemove} />}
    </Card>
  );
};
