import { GenerationMode } from "./types";
import { useEffect, useState } from "react";

export const modes: GenerationMode[] = ["normal", "busy", "off"];

export function promptMode(): GenerationMode | null {
  switch (
    window.prompt(
      "Type 2 or anything for full generation, 1 for busy day generation, 0 for day off generation"
    )
  ) {
    default:
      return "normal";
    case "1":
      return "busy";
    case "0":
      return "off";
    case null:
      return null;
  }
}

function getFromLocalStorage(key: string, defaultValue: any) {
  const persistedValue = localStorage.getItem(key);
  if (persistedValue) {
    try {
      return JSON.parse(persistedValue);
    } catch {
      return persistedValue;
    }
  }
  return defaultValue;
}

const usePersistedState = <T>(key: string, defaultValue: T) => {
  const [state, setState] = useState<T>(() => {
    return getFromLocalStorage(key, defaultValue);
  });
  const setLocalStoredState = (value: T) => {
    const valueToStore = value instanceof Function ? value(state) : value;
    localStorage.setItem(key, JSON.stringify(valueToStore));
    setState(valueToStore);
  };
  return [state, setLocalStoredState] as const;
};

export const usePersistedGenerationMode = (day: string) => {
  const key = `generationMode-${day}`;
  const state = usePersistedState<GenerationMode>(key, "normal");
  const [mode, setMode] = state;

  useEffect(() => {
    setMode(getFromLocalStorage(key, "normal"));
  }, [day]);

  return state;
};
