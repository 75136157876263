import { DayWithMeta } from "./types";

// export const getMonthlyWeightDifference = (
//   numberOfDays: number,
//   averageKcal: number
// ) => {
//   if (numberOfDays === 0) return 0;
//
//   // Constants
//   const kcalPerKg = 7700; // Convert pounds to kg
//
//   // Average daily caloric requirement for a 33-year-old male, 183cm tall
//   const weight = 75; // Assuming 75 kg weight
//   const BMR = 88.362 + 13.397 * weight + 4.799 * 183 - 5.677 * 33; // BMR calculation
//   const activityFactor = 1.55; // Moderate exercise 3-5 days a week
//   const dailyCaloricRequirement = BMR * activityFactor;
//
//   // Calculate the difference between consumed kcal and required kcal per day
//   const dailyKcalDifference = averageKcal - dailyCaloricRequirement;
//
//   // Calculate total kcal difference over the number of days
//   const totalKcalDifference = dailyKcalDifference * numberOfDays;
//
//   // Calculate weight difference in kg
//   const weightDifferenceKg = totalKcalDifference / kcalPerKg;
//
//   return weightDifferenceKg;
// };

export const weightDifferenceToText = (weightDifference: number) => {
  if (weightDifference === 0) return "-";
  if (weightDifference > 0) {
    return `+ ${weightDifference.toFixed(2)} kg`;
  } else {
    return `- ${-weightDifference.toFixed(2)} kg`;
  }
};

export const getAverageKcal = (days: DayWithMeta[]) => {
  const d = days.filter((day) => day.meta?.kcal);
  const sum = d.reduce((acc, day) => acc + day.meta!.kcal!, 0);
  return sum / d.length;
};
export type KcalRate =
  | "fasting"
  | "loss"
  | "maintain"
  | "maintain+"
  | "gain"
  | "gain+"
  | "gain++"
  | "gain+++"
  | "gain++++";

interface PersonalData {
  weight: number;
  height: number;
  age: number;
  gender: "male" | "female";
}

const defaultPersonalData: PersonalData = {
  weight: 75,
  height: 182,
  age: 33,
  gender: "male",
};

const getTDEE = ({ gender, weight, height, age }: PersonalData) => {
  let BMR: number;
  if (gender === "male") {
    BMR = 10 * weight + 6.25 * height - 5 * age + 5;
  } else {
    BMR = 10 * weight + 6.25 * height - 5 * age - 161;
  }

  // Estimate Total Daily Energy Expenditure (TDEE)
  const activityFactor = 1.55; // Assuming moderate activity level
  const TDEE = BMR * activityFactor;
  return TDEE;
};

export function getKcalRate(kcalIntake: number | undefined) {
  // Calculate Basal Metabolic Rate (BMR) using the Mifflin-St Jeor Equation

  if (!kcalIntake || kcalIntake < 480) {
    return undefined;
  }

  if (kcalIntake <= 800) {
    return "fasting";
  }

  const TDEE = getTDEE(defaultPersonalData);

  if (kcalIntake < TDEE * 0.85) {
    return "loss";
  } else if (kcalIntake <= TDEE * 1.0) {
    return "maintain";
  } else if (kcalIntake <= TDEE * 1.05) {
    return "maintain+";
  } else if (kcalIntake <= TDEE * 1.15) {
    return "gain";
  } else if (kcalIntake <= TDEE * 1.3) {
    return "gain+";
  } else if (kcalIntake <= TDEE * 1.45) {
    return "gain++";
  } else if (kcalIntake <= TDEE * 1.6) {
    return "gain+++";
  } else {
    return "gain++++";
  }
}

export const getWeightDifference = (kcal: number[]) => {
  const TDE = getTDEE(defaultPersonalData);
  const items = kcal.filter(Boolean);
  return items.reduce((acc, kcal) => acc + kcal - TDE, 0) / 7700;
};

export const getKcalColor = (
  rate: KcalRate | undefined
): string | undefined => {
  switch (rate) {
    case "fasting":
      return "#010932"; // DarkBlue, indicating fasting
    case "loss":
      return "#1E90FF"; // DodgerBlue, indicating a caloric deficit
    case "maintain":
      return "#32CD32"; // LimeGreen, indicating maintenance
    case "maintain+":
      return "#7CFC00"; // LawnGreen, indicating slight surplus
    case "gain":
      return "#FFA500"; // Orange, indicating moderate gain
    case "gain+":
      return "#FF4500"; // OrangeRed, indicating higher gain
    case "gain++":
      return "#B22222"; // FireBrick, indicating significant gain
    case "gain+++":
      return "#8B0000"; // DarkRed, indicating substantial gain
    case "gain++++":
      return "#7a035a"; // DarkMagenta, indicating substantial gain
    default:
      return undefined;
  }
};

export function getContrastingColor(hex: string): string {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate the relative luminance
  let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // If the luminance is greater than 0.5, return black, else return white
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
}
