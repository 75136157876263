import React, { FC, useEffect } from "react";
import { Layout } from "../components/Layout";
import { useMetaQuery, useUpdateMeta } from "../utils/queries";
import { Responsible } from "../utils/types";
import {
  parseHtmlString,
  QuillTextarea,
} from "../components/write/QuillTextarea";
import styled, { css } from "styled-components";
import { DayNavigation } from "../components/day/DayNavigation";
import { useParams } from "react-router";

const Wrapper = styled(Layout)<{ saved: boolean }>`
  width: 100%;
  max-width: 600px;

  .quill {
    width: 100%;
    margin: 0 auto;
    display: block;
    font-size: 1.2rem;
    border: none;
  }

  .ql-toolbar {
    display: none;
  }

  .ql-container {
    border: none !important;
    ${(props) =>
      props.saved
        ? css`
            background: mintcream;
          `
        : css`
            background: papayawhip;
          `};

    @media (min-width: 600px) {
      padding: 20px 40px;
    }
  }

  .ql-editor {
    border: none;
    font-size: 1.2rem;
    font-family: Helvetica, Arial, sans-serif;

    p {
      margin-bottom: 10px;
    }
  }
`;

const SaveBox = styled(Layout)`
  span {
    font-size: 2rem;
  }

  button {
    padding: 10px 40px;
  }
`;

interface WritePageParams {
  day: string;
  responsible: Responsible;

  [key: string]: string;
}

const checkIsSaved = (currentlyWritten: string, dbWritten: string) => {
  const currentToCompare = parseHtmlString(currentlyWritten).replace(/\s/g, "");
  const dbToCompare = parseHtmlString(dbWritten).replace(/\s/g, "");
  return currentToCompare === dbToCompare;
};

export const WritePage: FC = () => {
  const { day = "" } = useParams<WritePageParams>();

  const [meta, metaLoading] = useMetaQuery(day);
  const { written: dbWritten = "" } = meta || {};

  const updateMeta = useUpdateMeta();

  const update = (written: string) => {
    if (!meta) {
      return;
    }
    updateMeta({ ...meta, written });
  };

  const [currentlyWritten, setCurrentlyWritten] = React.useState("");

  useEffect(() => {
    setCurrentlyWritten(dbWritten);
  }, [day, dbWritten]);

  const isSaved = checkIsSaved(currentlyWritten, dbWritten);

  return (
    <Wrapper direction="column" gap={20} center saved={isSaved}>
      <DayNavigation day={day} isWrite />
      <QuillTextarea
        quill
        value={currentlyWritten}
        onChange={(value) => {
          setCurrentlyWritten(value);
        }}
        placeholder={`I. 3 things I'm grateful for.\nII. Something I've learned today.`}
      />
      <SaveBox direction="row" gap={20} center>
        {isSaved ? (
          <span>💿</span>
        ) : (
          <button type="button" onClick={() => update(currentlyWritten)}>
            SAVE
          </button>
        )}
      </SaveBox>
    </Wrapper>
  );
};
