type Action = () => void;

type ActionWithId = {
  id: string;
  action: Action;
};

export class Queue {
  private timeoutId?: NodeJS.Timeout;

  private actions: ActionWithId[] = [];

  public push(actionId: string, action: Action) {
    if (this.actions.find((action) => action.id === actionId)) {
      this.actions = this.actions.filter((action) => action.id !== actionId);
    }

    this.actions.push({ id: actionId, action });

    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      this.actions.forEach((action) => action.action());
      this.actions = [];
    }, 1000);
  }
}
