import React, { FC } from "react";
import {
  Link,
  LinkProps,
  NavigateOptions,
  useNavigate,
} from "react-router-dom";
import { To } from "react-router";

export const LinkKen: FC<LinkProps> = ({ to, ...props }) => {
  return <Link to={`/ken${to}`} {...props} />;
};
export const useNavigateKen = () => {
  const navigate = useNavigate();

  return (to: To, options?: NavigateOptions) => navigate(`/ken${to}`, options);
};
