import React, { FC } from "react";
import { Layout } from "../components/Layout";
import { Loader } from "../components/Loader";
import { PlansTable } from "../components/PlansTable";
import { useAllPlansQuery } from "../utils/queries";
import { siftOaths } from "../utils/oaths";

import { LinkOta } from "../navigation";

export const OathsPage: FC = () => {
  const { plans: allPlans = [], isLoading } = useAllPlansQuery();
  const { oaths } = siftOaths(allPlans || []);

  return (
    <Layout direction="column" gap={20}>
      <p className="color bigger center">
        <b>Pakty</b>
      </p>
      {isLoading ? <Loader /> : <PlansTable plans={oaths} mode="normal" />}
      <div />
      <LinkOta to={"/oath/add"} link>
        Dodaj więcej
      </LinkOta>
      <LinkOta to={"/"} link>
        Pokaż bieżący dzień
      </LinkOta>
    </Layout>
  );
};
