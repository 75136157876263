import { QuestDto } from "../dtos";

export const orderOptions = ["newest", "oldest", "random"] as const;
export type Order = typeof orderOptions[number];

export const getOrderIcon = (order: Order) => {
  switch (order) {
    case "newest":
      return "new";
    case "oldest":
      return "old";
    case "random":
      return "rand";
  }
};

const MAX_RANDOM_ORDER_LENGTH = 100;

export const applyOrder = (tasks: QuestDto[], order: Order, key: string) => {
  switch (order) {
    case "newest":
      return [...tasks].sort((a, b) => b.createdAt - a.createdAt);
    case "oldest":
      return [...tasks].sort((a, b) => a.createdAt - b.createdAt);
    case "random":
      return [...tasks].sort((a, b) => {
        return getRandomness(a) - getRandomness(b);
      });
    default:
      return tasks;
  }
};

function getRandomness(task: QuestDto) {
  const textNum = textToNumber(task.name);
  const dayNum = textNum * (new Date().getUTCDate() + 1);

  const threeDigitNum = Number(String(dayNum).substring(0, 3));
  return threeDigitNum;
}

function textToNumber(str: string): number {
  let result = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    // You can use various operations here. For simplicity, let's just add them.
    result += charCode;
  }
  return result;
}
