import { useEffect } from "react";

export const useRememberScrollPosition = (key: string): void => {
  // Function to save the current scroll position to local storage
  const saveScrollPosition = (): void => {
    const scrollPosition = window.scrollY;
    localStorage.setItem(key, JSON.stringify(scrollPosition));
  };

  // Function to retrieve and set the scroll position from local storage
  const setScrollPosition = (): void => {
    const savedPosition = localStorage.getItem(key);
    const scrollY = savedPosition ? parseInt(JSON.parse(savedPosition), 10) : 0;
    window.scrollTo(0, scrollY);
  };

  useEffect(() => {
    // Set scroll position when the component mounts
    setScrollPosition();

    // Add scroll event listener
    window.addEventListener("scroll", saveScrollPosition);

    // Clean up function
    return () => {
      window.removeEventListener("scroll", saveScrollPosition);
    };
  }, []);
};

export default useRememberScrollPosition;
