import { Field, Form, Formik } from "formik";
import { FC } from "react";
import styled from "styled-components";
import { IPlan } from "../utils/types";
import { Layout } from "./Layout";
import { OathWrapper } from "./OathWrapper";
import { FrequencySelectV2 } from "./FrequencySelectV2";
import { Frequency } from "../utils/text";

export type IPlanWithoutId = Omit<IPlan, "_id">;

interface PlanFormProps {
  plan?: IPlan;
  submitText: string;
  onSubmit: (plan: IPlanWithoutId) => void;
  oathForm: boolean;
  onSuccess: () => void;
}

type FieldName = keyof IPlanWithoutId;

const CHORE_NAME_ID: FieldName = "choreName";
const DETAILS_ID: FieldName = "details";
const ACTIVE_ID: FieldName = "active";

const StyledForm = styled(Form)`
  max-width: 600px;
`;

const getFrequencyNonWeekend = (frequency: string) => {
  const splitted = frequency.split("/");
  return splitted[0] + "/" + splitted[1];
};

const getFrequencyWeekend = (frequency: string) => {
  const splitted = frequency.split("/");

  return splitted[2] ? splitted[2] + "/1" : undefined;
};

export const PlanForm: FC<PlanFormProps> = (props) => {
  const { plan, submitText, oathForm, onSubmit, onSuccess } = props;

  const initialValues: IPlanWithoutId = plan || {
    choreName: "",
    details: "",
    responsible: "grzes",
    active: true,
    isOath: oathForm,

    frequencyNormal: Frequency.Daily,
    frequencyBusy: oathForm ? Frequency.Daily : Frequency.Zero,
    frequencyOff: oathForm ? Frequency.Daily : Frequency.Zero,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        let {
          choreName,
          details,
          responsible,
          active,
          isOath,

          frequencyNormal,
          frequencyBusy,
          frequencyOff,
        } = values;

        if (
          !choreName ||
          !frequencyNormal ||
          !frequencyBusy ||
          !frequencyOff ||
          !responsible
        ) {
          return;
        }

        await onSubmit({
          choreName,
          details,
          responsible,
          active,
          isOath,
          frequencyNormal,
          frequencyBusy,
          frequencyOff,
        });
        onSuccess();
      }}
    >
      <StyledForm>
        <Layout direction="column" gap={40}>
          <label>
            <OathWrapper oath={oathForm}>
              <Layout direction="column" gap={10}>
                <Layout direction="column" gap={10}>
                  <span className="color">Treść</span>
                  <Field
                    type="text"
                    className="treść"
                    name={CHORE_NAME_ID}
                    required
                  />
                </Layout>
                <Layout direction="column" gap={10}>
                  <span className="color">Szczegóły</span>
                  <Field
                    type="text"
                    className="treść"
                    name={DETAILS_ID}
                    as="textarea"
                    rows={4}
                  />
                </Layout>
              </Layout>
            </OathWrapper>
          </label>

          {!oathForm && (
            <Layout direction="column" gap={20}>
              <FrequencySelectV2 field={"frequencyNormal"} />
              <FrequencySelectV2 field={"frequencyBusy"} />
              <FrequencySelectV2 field={"frequencyOff"} />
            </Layout>
          )}

          {!oathForm && (
            <label>
              <Layout direction="row" gap={10}>
                <span className="color">Aktywne?</span>
                <Field type="checkbox" name={ACTIVE_ID} />
              </Layout>
            </label>
          )}

          {!oathForm && <div />}
          <button type="submit">{submitText}</button>
        </Layout>
      </StyledForm>
    </Formik>
  );
};
