import { LinkOta } from "../navigation";
import React from "react";
import { Layout } from "./Layout";

export const NavigationLinks = () => {
  return (
    <Layout direction="column" gap={10}>
      <LinkOta to={"/plan"} link>
        Pokaż plany
      </LinkOta>
      <LinkOta to={"/oath"} link>
        Pokaż pakty
      </LinkOta>
      <LinkOta to={"/calendar"} link>
        Pokaż kalendarz
      </LinkOta>
      <LinkOta to={"/settings"} link>
        Pokaż ustawienia
      </LinkOta>
    </Layout>
  );
};
