import React, { FC } from "react";
import { useParams } from "react-router";
import { Layout } from "../components/Layout";
import { Loader } from "../components/Loader";
import { PlanForm } from "../components/PlanForm";
import { useDeletePlan, usePlanQuery, useUpdatePlan } from "../utils/queries";

import { LinkOta, useNavigateOta } from "../navigation";

export const PlanOrOathEditPage: FC<{ type: "plan" | "oath" }> = ({ type }) => {
  const { id } = useParams();
  const navigate = useNavigateOta();

  const { plan, isLoading } = usePlanQuery(id);
  const updatePlan = useUpdatePlan();

  const deletePlan = useDeletePlan();
  const onDelete = () => {
    if (window.confirm("Czy na pewno usunąć?")) {
      deletePlan(id!);
      navigate(backLink);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!plan) return <p className="color">Plan not found</p>;

  const isOath = type === "oath";
  const backLink = isOath ? "/oath" : "/plan";

  return (
    <Layout direction="column" gap={20}>
      <PlanForm
        plan={plan}
        submitText="Zmień"
        oathForm={isOath}
        onSubmit={(updated) => {
          if (!plan?._id) {
            return;
          }
          updatePlan({ ...updated, _id: plan._id });
        }}
        onSuccess={() => navigate(backLink)}
      />

      <Layout direction="row" gap={20}>
        <button type="button" onClick={onDelete}>
          Usuń
        </button>
        <LinkOta to={backLink} link>
          Anuluj
        </LinkOta>
      </Layout>
    </Layout>
  );
};
