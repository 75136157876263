export enum Frequency {
  Zero = "0/1",
  Daily = "1/1",
  Every2Days = "1/2",
}

export const frequencyText = (frequency: string | undefined) => {
  if (!frequency) {
    return "?";
  }

  const [times, daysToGenerate, weekendTimes] = frequency.split("/");

  if (daysToGenerate !== "1") {
    return `Co ${daysToGenerate} dni`;
  }

  let timesText = "";

  if (times === "0") {
    timesText = "-";
  } else if (times === "1") {
    timesText = "Raz dziennie";
  } else {
    timesText = `${times} razy dziennie`;
  }

  if (weekendTimes && weekendTimes !== times) {
    return `${timesText} (w weekendy: ${weekendTimes || "-"})`;
  }

  return timesText;
};
