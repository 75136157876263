import { Box, Button, Text } from "grommet";
import React, { FC } from "react";
import { TagBar } from "./TagBar";
import { useTagUtil } from "../util/tagUtil";
import { TagDto } from "../dtos";

interface TagButtonProps {
  tag: TagDto;
  onClick: () => void;
  highlight?: boolean;
}

export const TagButton: FC<TagButtonProps> = (props) => {
  const { tag, onClick, highlight } = props;
  const { getTagOpenCount } = useTagUtil();

  const openCount = getTagOpenCount(tag);

  return (
    <Button onClick={onClick} style={{ minWidth: "130px" }}>
      <Box
        justify="center"
        gap="xsmall"
        border={highlight ? { color: "white" } : undefined}
      >
        <Box gap="xxsmall" direction="row" justify="center" align="center">
          <Text>
            {tag.icon} {tag.name}
          </Text>{" "}
          <Box direction="row" align="center">
            <Text size="small">{openCount[0] || ""}</Text>
            {/*{openCount[1] > 0 && (*/}
            {/*  <Text size="small" color="gray">*/}
            {/*    &nbsp;|&nbsp;{openCount[1]}*/}
            {/*  </Text>*/}
            {/*)}*/}
          </Box>
        </Box>
        <TagBar tagIds={[tag.tagId]} />
      </Box>
    </Button>
  );
};

export const TagButtonTiny: FC<TagButtonProps> = (props) => {
  const { tag, onClick } = props;

  const label = tag.name.slice(0, 3);

  return (
    <Button
      onClick={onClick}
      style={{
        width: "50px",
        height: "25px",
        borderRadius: "20%",
        background: tag.color,
        padding: "5px",
        opacity: 0.7,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      color="white"
    >
      <Text size="small">
        {tag.icon}
        {label}
      </Text>
    </Button>
  );
};
