import { Box } from "grommet";
import React, { FC, ReactNode } from "react";

interface PanelProps {
  children: ReactNode;
  bottomChildren?: ReactNode;
  position: "top" | "bottom";
}

export const Panel: FC<PanelProps> = (props) => {
  const position =
    props.position === "top"
      ? {
          top: 0,
        }
      : { bottom: 0 };

  return (
    <Box
      background="second"
      style={{ position: "fixed", ...position, left: 0, right: 0 }}
    >
      <Box
        gap="medium"
        direction="row"
        justify="between"
        align="center"
        style={{ width: "100%", maxWidth: "1200px", margin: "0 auto" }}
        pad={{ horizontal: "large", vertical: "small" }}
      >
        {props.children}
      </Box>
      {props.bottomChildren}
    </Box>
  );
};
