import { DayStatus } from "./types";
import { getDebugDate } from "./localStorage";

type DateString = string;

export const now = () => {
  return getDebugDate() || new Date();
};

export const leadingZero = (digits: number) => {
  return digits.toString().length === 1 ? `0${digits}` : digits;
};

export const dateToString = (date: Date): DateString => {
  date = new Date(date);
  const year = date.getFullYear();
  const month = leadingZero(date.getMonth() + 1);
  const day = leadingZero(date.getDate());

  return `${year}-${month}-${day}`;
};

export const stringToDate = (dateString: DateString): Date => {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day);
};

export const nextDayString = (dateString: DateString): DateString => {
  const date = stringToDate(dateString);
  date.setDate(date.getDate() + 1);
  return dateToString(date);
};

export const previousDayString = (dateString: DateString): DateString => {
  const date = stringToDate(dateString);
  date.setDate(date.getDate() - 1);
  return dateToString(date);
};

export const getDayStatus = (day: DateString, now: Date): DayStatus => {
  const currentDay = dateToString(now);
  if (day === currentDay) {
    return "current";
  }
  if (day < currentDay) {
    return "past";
  } else {
    return "future";
  }
};

export const getMonthString = (date: Date) => {
  const month = date.getMonth();
  const year = date.getFullYear();
  return `${year}-${leadingZero(month + 1)}`;
};

export const nextMonthString = (monthString: string) => {
  const [year, month] = monthString.split("-").map(Number);
  const date = new Date(year, month);
  return getMonthString(date);
};

export const previousMonthString = (monthString: string) => {
  const [year, month] = monthString.split("-").map(Number);
  const date = new Date(year, month - 2);
  return getMonthString(date);
};

export const getMonthDays = (monthString: string) => {
  const [year, month] = monthString.split("-").map(Number);
  const date = new Date(year, month - 1, 1);
  const days = [];
  while (date.getMonth() === month - 1) {
    days.push(dateToString(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};
