import React, { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles for the default theme

interface TextareaProps {
  value: string;
  onChange: (content: string) => void;
  quill?: boolean;
}

export function parseHtmlString(input: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, "text/html");

  let parsedText = "";

  // Iterate through paragraphs, ordered lists, and unordered lists
  doc.querySelectorAll("p, ol, ul").forEach((element) => {
    if (element.tagName.toLowerCase() === "p") {
      // Add paragraph text
      parsedText += element.textContent + "\n";
    } else if (
      element.tagName.toLowerCase() === "ol" ||
      element.tagName.toLowerCase() === "ul"
    ) {
      // Add each list item
      element.querySelectorAll("li").forEach((li, index) => {
        // Format for ordered list (1., 2., ...) or unordered list (-)
        const prefix =
          element.tagName.toLowerCase() === "ol" ? `${index + 1}. ` : "- ";
        parsedText += prefix + li.textContent + "\n";
      });
    }
  });

  return parsedText;
}

export const QuillTextarea: React.FC<
  TextareaProps & { placeholder?: string }
> = ({ quill, ...props }) => {
  const quillRef = useRef<ReactQuill>(null);

  const handleKeyDown = (e: KeyboardEvent) => {
    const value = encodeURI(parseHtmlString(props.value));

    if (e.key === "@") {
      e.preventDefault();
      const translateUrl = `https://translate.google.pl/?hl=pl&sl=en&text=${value}&op=translate`;
      window.open(translateUrl, "_blank");
    }
    if (e.key === "#") {
      e.preventDefault();
      const deepLUrl = `https://www.deepl.com/pl/translator#en/pl/${value}`;
      window.open(deepLUrl, "_blank");
    }
    // props.onKeyDown?.(e);
  };

  // useEffect(() => {
  //   if (quill && quillRef.current) {
  //     const quillEditor = quillRef.current.getEditor?.();
  //     quillEditor.keyboard.addBinding({ key: "@" }, handleKeyDown);
  //     quillEditor.keyboard.addBinding({ key: "#" }, handleKeyDown);
  //   }
  // }, [quill]);

  if (quill) {
    return (
      <ReactQuill
        {...props}
        ref={quillRef}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={(e) => handleKeyDown(e as unknown as KeyboardEvent)}
      />
    );
  } else {
    return (
      <textarea
        {...props}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e as unknown as KeyboardEvent)}
      />
    );
  }
};
