import { Text } from "grommet";
import React, { FC } from "react";
import styled from "styled-components";
import { tagParam, useMapTags } from "../util/tags";

import { LinkKen } from "../navigation";

const TagItem = styled(LinkKen)`
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  color: inherit;
  text-decoration: none;
`;

interface TagsListProps {
  tagIds: string[];
}

export const TagList: FC<TagsListProps> = (props) => {
  const { tagIds } = props;
  const tags = useMapTags(tagIds);

  if (!tags.length) {
    return null;
  }

  return (
    <Text size="xsmall" style={{ opacity: 0.5 }}>
      {tags.map((tag) => (
        <TagItem
          key={tag.name}
          to={"/" + tagParam(tag.name)}
          onClick={(e) => e.stopPropagation()}
        >
          #{tag.name}
        </TagItem>
      ))}
    </Text>
  );
};

TagList.displayName = "TagList";
