import { useEffect, useState } from "react";

export const usePersistedState = <T>(key: string, defaultValue: T) => {
  const [state, setState] = useState<T>(() => {
    const persistedValue = localStorage.getItem(key);
    if (persistedValue) {
      try {
        return JSON.parse(persistedValue);
      } catch {
        return persistedValue;
      }
    }
    return defaultValue;
  });
  const setLocalStoredState = (value: T) => {
    const valueToStore = value instanceof Function ? value(state) : value;
    localStorage.setItem(key, JSON.stringify(valueToStore));
    setState(valueToStore);
  };
  return [state, setLocalStoredState] as const;
};

export const isMobile = () => {
  return window.innerWidth <= 600;
};

export const msToSeconds = (ms: number) => Math.floor(ms / 1000);

export const useSeconds = () => {
  const [second, setSecond] = useState(msToSeconds(Date.now()));

  useEffect(() => {
    const interval = setInterval(() => {
      setSecond(msToSeconds(Date.now()));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return second;
};

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T | ((val: T) => T)) => void] {
  // Retrieve stored value from local storage or use the initial value if not found
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  // Update the state and save the new value to local storage
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      setStoredValue((stored) => {
        const valueToStore = value instanceof Function ? value(stored) : value;
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
        return valueToStore;
      });
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
}
