import React, { FC, ReactNode } from "react";
import { SettingsContext } from "../utils/context";
import { useSettingsQuery } from "../utils/queries";

export const SettingsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [settings] = useSettingsQuery();

  if (!settings) {
    return null;
  }

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};
