import { FC } from "react";
import styled, { css } from "styled-components";
import { useToggleChoreStatus } from "../utils/queries";
import { IChore, IEntry } from "../utils/types";
import {
  ChoreTag,
  choreTags,
  getEntryRemainingDays,
  hasChoreTag,
} from "../utils/chore";

const EntryCell = styled.td`
  text-align: center;
`;

const EntryCellContent = styled.button<{
  hasPrevious: boolean;
  hasNext: boolean;
  done: boolean;
}>`
  background: white;
  box-sizing: border-box;
  width: 100%;

  .checkmark {
    visibility: hidden;
  }

  .special b {
    display: block;
    transform: scale(1.8, 1.5);
  }

  ${(props) =>
    props.done &&
    css`
      background: var(--main-color);
      color: white;

      .checkmark {
        visibility: visible;
      }
    `}
`;

interface ChoresTableEntryCellProps {
  chore: IChore;
  entry: IEntry;
  colSpan: number;
}

export const ChoresTableEntryCell: FC<ChoresTableEntryCellProps> = (props) => {
  const { chore, entry, colSpan } = props;
  const tags = choreTags(chore.choreName);

  const toggleStatus = useToggleChoreStatus();

  const isWork = hasChoreTag(chore.choreName, ChoreTag.WORK);

  const onClick = async () => {
    toggleStatus({
      choreId: chore._id,
      entryId: entry._id,
      sharedId: entry.sharedId,
    });
  };

  const hasPrevious = Boolean(entry.multiDay && entry.multiDay.num > 1);
  const hasNext = Boolean(
    entry.multiDay && entry.multiDay.num < entry.multiDay.of
  );
  const remainingDays = getEntryRemainingDays(entry);

  return (
    <EntryCell colSpan={colSpan}>
      <EntryCellContent
        type="button"
        onClick={onClick}
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        done={entry.done}
      >
        {(!remainingDays || entry.done) && isWork ? (
          <span className="checkmark special">
            <b>🔥</b>
          </span>
        ) : (
          <span className="checkmark">✓</span>
        )}
        {
          <span>
            {remainingDays < 4
              ? "⧖".repeat(remainingDays)
              : remainingDays + "⧖"}
          </span>
        }
      </EntryCellContent>
    </EntryCell>
  );
};
