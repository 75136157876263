import { Box, Spinner } from "grommet";
import { FC } from "react";

export const BigLoader: FC = () => {
  return (
    <Box align="center">
      <Spinner size="large" color="second" />
    </Box>
  );
};
