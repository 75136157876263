import React, { FC, useState } from "react";
import { Layout } from "../components/Layout";
import { Loader } from "../components/Loader";
import { PlansTable } from "../components/PlansTable";
import { useAllPlansQuery } from "../utils/queries";
import { siftOaths } from "../utils/oaths";
import { GenerationMode } from "../utils/types";
import { modes } from "../utils/mode";

import { LinkOta } from "../navigation";
import styled from "styled-components";
import { Box } from "grommet";
import { isMobile } from "../../common/util/react";

const ModeSelect = styled(Layout)``;

export const PlansPage: FC = () => {
  const { plans: allPlans = [], isLoading } = useAllPlansQuery();
  const { rest: plans } = siftOaths(allPlans || []);

  const allowAll = !isMobile();

  const [mode, setMode] = useState<GenerationMode | null>(
    allowAll ? null : "normal"
  );

  const modesOptions = allowAll ? [null, ...modes] : modes;

  const activePlans = plans.filter((plan) => plan.active);
  const inactivePlans = plans.filter((plan) => !plan.active);

  const [showInactive, setShowInactive] = useState(false);

  return (
    <Layout direction="column" gap={20}>
      <p className="color bigger center">
        <b>Plany</b>
      </p>
      {isLoading ? (
        <Loader />
      ) : (
        <Layout direction="column" gap={10}>
          <ModeSelect direction="row" gap={10}>
            {modesOptions.map((m) => (
              <button
                key={m}
                type="button"
                className={`mode ${m === mode ? "active" : ""}`}
                onClick={() => setMode(m)}
              >
                {m || "all"}
              </button>
            ))}
          </ModeSelect>
          <PlansTable plans={activePlans} mode={mode} />

          <Box margin={{ vertical: "medium" }}>
            {showInactive ? (
              <PlansTable plans={inactivePlans} mode={mode} />
            ) : (
              <a
                className="align-start bigger-link"
                onClick={() => setShowInactive(true)}
              >
                Pokaż nieaktywne
              </a>
            )}
          </Box>
        </Layout>
      )}
      <div />
      <LinkOta to={`/plan/add?mode=${mode}`} link>
        Dodaj coś jeszcze
      </LinkOta>
      <LinkOta to={"/"} link>
        Pokaż bieżący dzień
      </LinkOta>
    </Layout>
  );
};
