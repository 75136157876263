import { FC } from "react";
import { useParams } from "react-router";
import { getMonthDays, getMonthString, now, stringToDate } from "../utils/date";
import { useMonthMetaQuery } from "../utils/queries";
import { MonthNavigation } from "../components/day/DayNavigation";
import styled from "styled-components";
import { WriteLink } from "../components/WriteLink";
import { LinkOta } from "../navigation";
import { isToday as isTodayFn, isWeekend as isWeekendFn } from "date-fns";
import { DayWithMeta } from "../utils/types";
import {
  getAverageKcal,
  getContrastingColor,
  getKcalColor,
  getKcalRate,
  getWeightDifference,
  weightDifferenceToText,
} from "../utils/kcal";

const CalendarWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  td {
    border: 1px solid #a9a9a9;
  }
`;

const Tr = styled.tr<{
  isWeekend?: boolean;
  isToday?: boolean;
  separate?: boolean;
}>`
  ${(props) =>
    props.isWeekend &&
    `
    background-color: #eee;
  `}

  ${(props) =>
    props.isToday &&
    `
    border: 2px solid red;
  `}
    
    ${(props) =>
    props.separate &&
    `
        border-top: 8px solid #eee;
    `}
`;

export const CalendarPage: FC = () => {
  const { month } = useParams();
  const monthString = month || getMonthString(now());

  const [monthMeta, loading] = useMonthMetaQuery(monthString);

  const monthDaysWithMeta: DayWithMeta[] = getMonthDays(monthString).map(
    (day) => {
      const meta = monthMeta?.find((m) => m.day === day);
      return {
        day,
        meta,
      };
    }
  );

  const monthAverageKcal = getAverageKcal(monthDaysWithMeta);
  const monthlyWeightDifference = getWeightDifference(
    monthDaysWithMeta.map((d) => d.meta?.kcal || 0)
  );

  const difference = weightDifferenceToText(monthlyWeightDifference);

  return (
    <CalendarWrapper>
      <MonthNavigation month={monthString} />
      <LinkOta to="/" link>
        Wróć
      </LinkOta>
      <table>
        <tbody>
          {monthDaysWithMeta.map(({ day, meta }) => {
            const dayDate = stringToDate(day);
            const isWeekend = isWeekendFn(dayDate);
            const isToday = isTodayFn(dayDate);

            const kcalColor = getKcalColor(getKcalRate(meta?.kcal));

            return (
              <Tr key={day} isToday={isToday} isWeekend={isWeekend}>
                <td>{day}</td>
                <KcalTd kcal={meta?.kcal} />
                <td>
                  <WriteLink day={day} written={meta?.written} />
                </td>
              </Tr>
            );
          })}
          <Tr separate>
            <td>Average</td>
            <KcalTd kcal={monthAverageKcal} />
            <td>{difference}</td>
          </Tr>
        </tbody>
      </table>
      <LinkOta to="/" link>
        Wróć
      </LinkOta>
    </CalendarWrapper>
  );
};

const KcalTd = ({ kcal }: { kcal?: number }) => {
  const kcalColor = getKcalColor(getKcalRate(kcal));
  return (
    <td
      style={{
        textAlign: "center",
        backgroundColor: kcalColor,
        color: kcalColor && getContrastingColor(kcalColor),
      }}
    >
      {kcal?.toFixed(0)}
    </td>
  );
};
