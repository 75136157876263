import { Box, Text } from "grommet";
import { FC, ReactNode } from "react";
import styled from "styled-components";
import { taskUtil } from "../util/task";
import { TaskGroup } from "./TaskGroup";
import { QuestDto } from "../dtos";

interface TaskListProps {
  tasks: QuestDto[];
  loading?: boolean;
  label?: string;
  showLength?: boolean;
  versatile?: boolean;
  tagImportantTag?: string;
}

const Separator = styled.div<{ margin: number }>`
  height: 1px;
  background-color: #ebebeb;
  width: 100%;
  margin-top: ${(props) => props.margin}px;
  margin-bottom: ${(props) => props.margin + 20}px;
`;

export const TaskList: FC<TaskListProps> = (props) => {
  const { tasks, tagImportantTag } = props;

  const { due, inbox, important, planned, toBePlanned } =
    taskUtil(tasks).newPriorities(tagImportantTag);

  const content = (() => {
    const result: ReactNode[] = [];
    let somethingRendered = false;

    let index = 0;
    for (const group of [due, important, inbox, planned, toBePlanned]) {
      index++;
      if (group.isEmpty()) {
        continue;
      }
      if (somethingRendered) {
        result.push(<Separator margin={20} />);
      }
      somethingRendered = true;

      result.push(
        <TaskGroup tasks={group.tasks} key={index} groupId={index.toString()} />
      );
    }
    return result;
  })();

  return (
    <Box gap="large" align="center">
      {!tasks.length && (
        <Box align="center">
          <Text>Pusto.</Text>
        </Box>
      )}

      {content}
    </Box>
  );
};
