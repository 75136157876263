import React, { FC, useState } from "react";
import { Box, Button, Grid, Text } from "grommet";
import { TagButton, TagButtonTiny } from "./TagButton";
import { useGlobal } from "../global";
import { Add } from "grommet-icons";
import { Modal } from "./Modal";
import { TagForm } from "./TagForm";

interface TagPickerProps {
  tags: string[];
  onChange: (tags: string[]) => void;
}

export const TagPicker: FC<TagPickerProps> = (props) => {
  const { tags, onChange } = props;
  const { activeTags, lastTags } = useGlobal();
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const withTagRemoved = (tag: string) => {
    return tags.filter((tg) => tg !== tag);
  };

  const onAddName = (tagName: string) => {
    onChange([...tags, tagName]);
    closeModal();
  };

  const lastTagsToDisplay = lastTags.filter((tag) => !tags.includes(tag.name));

  return (
    <Box gap="small" direction="column">
      <Text>Tagi</Text>

      <Grid columns="xsmall" gap="large">
        {activeTags
          .filter((tag) => tags.includes(tag.name))
          .map((tag) => (
            <TagButton
              tag={tag}
              onClick={() => {
                onChange(withTagRemoved(tag.name));
              }}
            />
          ))}
        <Button icon={<Add />} onClick={openModal} />
      </Grid>
      <Box direction="row" gap="small" align="end">
        <Text size="small">Ostatnie:</Text>
        {lastTagsToDisplay.map((tag) => (
          <TagButtonTiny
            tag={tag}
            onClick={() => {
              onChange([...tags, tag.name]);
            }}
          />
        ))}
      </Box>
      {modalOpen && (
        <Modal onClickOutside={closeModal}>
          <Box pad="small">
            <Grid pad="medium" gap="large" columns="xsmall">
              {activeTags
                .filter((tag) => !tags.includes(tag.name))
                .map((tag) => (
                  <TagButton tag={tag} onClick={() => onAddName(tag.name)} />
                ))}
            </Grid>
            <Box margin={{ vertical: "medium" }}>
              <TagForm onAdded={(newTagName) => onAddName(newTagName)} />
            </Box>
            <Button
              onClick={closeModal}
              label="Zamknij"
              size="large"
              margin={{ top: "medium" }}
            />
          </Box>
        </Modal>
      )}
    </Box>
  );
};
