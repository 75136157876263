import { Page } from "grommet";
import React, { FC } from "react";
import { CollapseBox } from "../components/CollapseBox";
import { MyLink } from "../components/MyLink";
import { TaskList } from "../components/TaskList";
import { taskByDoneTime } from "../util/filter";
import { useGlobal } from "../global";

export const DonePage: FC = () => {
  const { tasks } = useGlobal();
  const {
    doneToday,
    doneYesterday,
    done2DaysAgo,
    done3DaysAgo,
    done4DaysAgo,
    done5DaysAgo,
    done6DaysAgo,
    doneBefore,
  } = taskByDoneTime(tasks);

  return (
    <Page pad="medium" gap="large">
      <MyLink to={"/"}>Wróć</MyLink>
      <TaskList tasks={doneToday} label="Zrobione dziś" showLength />
      <CollapseBox showText="Pokaż starsze">
        <TaskList tasks={doneYesterday} label="Zrobione wczoraj" showLength />
        <TaskList tasks={done2DaysAgo} label="Zrobione 2 dni temu" showLength />
        <TaskList tasks={done3DaysAgo} label="Zrobione 3 dni temu" showLength />
        <TaskList tasks={done4DaysAgo} label="Zrobione 4 dni temu" showLength />
        <TaskList tasks={done5DaysAgo} label="Zrobione 5 dni temu" showLength />
        <TaskList tasks={done6DaysAgo} label="Zrobione 6 dni temu" showLength />
        <TaskList tasks={doneBefore} label="Zrobione wcześniej" showLength />
      </CollapseBox>
    </Page>
  );
};
