import { useGlobal } from "../global";
import { taskUtil } from "./task";
import { TagDto } from "../dtos";

export const useTagUtil = () => {
  const { activeTags, tasks } = useGlobal();

  const getTagByName = (name: string) => {
    return activeTags.find((tag) => tag.name === name);
  };

  const getTagOpenCount = (tag: TagDto) => {
    return taskUtil(tasks).byTag(tag.name, activeTags).countReport();
  };

  const getTagsByOpenCount = () => {
    return [...activeTags].sort((a, b) => {
      return (
        getTagOpenCount(b)[0] - getTagOpenCount(a)[0] ||
        getTagOpenCount(b)[1] - getTagOpenCount(a)[1]
      );
    });
  };

  return { getTagByName, getTagOpenCount, getTagsByOpenCount };
};
