import { Box, Card, CheckBox, Menu, Text } from "grommet";
import { Alert, CircleInformation, Clock, Star } from "grommet-icons";
import React, { FC, useEffect } from "react";
import { Queue } from "../queue";
import { TagBar } from "./TagBar";
import { TagList } from "./TagList";
import { useGlobal } from "../global";
import { dueColor, getStaleDays } from "../util/task";
import { QuestDto } from "../dtos";

import { useNavigateKen } from "../navigation";
import { askAi } from "../util/ask-ai";

const STALE_DAYS_THRESHOLD = 7;
const SUPER_STALE_DAYS_THRESHOLD = 14;

export interface TaskItemProps {
  task: QuestDto;

  isOta?: boolean;

  time?: number;

  onToggle(data: { id: string; markAsDone: boolean }): void;
}

const toggleQueue = new Queue();

const getClassname = (isStale: boolean) =>
  isStale ? "task-item shake shake-constant" : "task-item";

const getRenderedPriority = (task: QuestDto) => {
  if (task.done) {
    return "planned";
  }
  if (task.priority === "tag-important") {
    return "important";
  }

  return task.priority;
};

export const TaskItem: FC<TaskItemProps> = (props) => {
  const { task, onToggle, isOta, time } = props;
  const navigate = useNavigateKen();
  const { currentTag } = useGlobal();
  const [markedDone, setMarkedDone] = React.useState(task.done);

  useEffect(() => {
    setMarkedDone(task.done);
  }, [task.done]);

  const renderedPriority = getRenderedPriority(task);

  const getColor = () => {
    switch (renderedPriority) {
      case "inbox":
        return "#c2f6d0";
      case "important":
        return "#fefaf7";
      case "planned":
        return "#FFFFFF";
      case "to-be-planned":
        return "#ebebeb";
    }
  };

  const staleDays = getStaleDays(task);
  const isStale = staleDays > STALE_DAYS_THRESHOLD;
  const isSuperStale = staleDays > SUPER_STALE_DAYS_THRESHOLD;

  const content = (
    <Box gap="small" direction="row" align="center">
      {(task.priority === "important" || task.priority === "tag-important") && (
        <Star />
      )}
      {task.dueBy && <Clock color={dueColor(task.dueBy, time)} />}
      <Text
        size="normal"
        weight="normal"
        style={{ display: "flex", alignItems: "center" }}
      >
        {task.name}
        {task.description && (
          <CircleInformation
            onClick={(e) => {
              e.stopPropagation();
              alert(task.description);
            }}
            style={{ marginLeft: "10px", cursor: "pointer" }}
          />
        )}
        {task.priority === "important" && isStale && (
          <Box
            direction="row"
            align="center"
            margin={{ horizontal: "small" }}
            gap="2px"
          >
            <Alert color="orange" size="15px" />
            <Text size="15px" color="orange">
              {staleDays}
            </Text>
          </Box>
        )}
      </Text>
    </Box>
  );

  const onClickToggle = () => {
    const newState = !markedDone;
    setMarkedDone(!markedDone);
    toggleQueue.push(`toggle-${task._id}`, () => {
      if (task.done === newState) {
        return;
      }
      onToggle({ id: task._id, markAsDone: newState });
    });
  };

  const controls = (
    <Box
      direction="row"
      gap="small"
      align="center"
      justify="between"
      style={{ minWidth: "40px" }}
    >
      <CheckBox
        checked={markedDone}
        onChange={onClickToggle}
        onClick={(e) => e.stopPropagation()}
      />
      <Menu
        items={[
          {
            label: "Ask AI",
            onClick: (e) => {
              e.stopPropagation();
              askAi(task);
            },
          },
        ]}
        onClick={(e) => e.stopPropagation()}
      />
    </Box>
  );

  const id = "card-item-" + task._id;
  const queryParams = new URLSearchParams();
  if (currentTag) {
    queryParams.set("tag", currentTag);
  }
  if (isOta) {
    queryParams.set("ota", "true");
  }

  const editLink = `/edit/${task._id}/? + ${queryParams.toString()}`;

  return (
    <Card
      pad="medium"
      elevation="small"
      background={getColor()}
      style={{ border: "1px solid #CACACC" }}
      id={id}
      onClick={() => {
        navigate(editLink);
      }}
      width={{ width: "100%", max: "large" }}
      className={getClassname(task.priority === "important" && isSuperStale)}
    >
      <Box gap="small" direction="column">
        <TagBar tagIds={task.tags} />
        <TagList tagIds={task.tags} />
        <Box direction="row" gap="small" justify="between">
          {content}
          {controls}
        </Box>
      </Box>
    </Card>
  );
};
