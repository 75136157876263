import styled, { css } from "styled-components";
import { FC, ReactNode, useContext } from "react";
import { ModalContext } from "../utils/context";
import { Layout } from "./Layout";
import { createPortal } from "react-dom";

const ModalArea = styled.div<{ visible: boolean; background?: string }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;

  ${(props) =>
    props.visible
      ? css`
          background: ${props.background
            ? props.background
            : "rgba(0, 0, 0, 0.5)"};
          opacity: 1;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`;

const ModalBox = styled(Layout)<{ padding: string }>`
  background: white;
  padding: ${(props) => props.padding};
`;

const ButtonsLayout = styled(Layout)`
  justify-content: space-between;
`;

export const Confirm = () => {
  const { _visible, _content, _onYes, _onNo, _config } =
    useContext(ModalContext);
  const { padding = "20px 40px", buttons = true, background } = _config || {};

  return (
    <Modal padding={padding} background={background} visible={_visible}>
      {_content}
      {buttons && (
        <ButtonsLayout direction="row" gap={20}>
          <button onClick={_onNo}>Nie, już to ogarniam!</button>
          <button onClick={_onYes}>Tak ♡</button>
        </ButtonsLayout>
      )}
    </Modal>
  );

  return (
    <ModalArea visible={_visible} background={background}>
      <ModalBox padding={padding}>
        <Layout direction="column" gap={10}></Layout>
      </ModalBox>
    </ModalArea>
  );
};

interface ModalProps {
  children: ReactNode;
  padding: string;
  background?: string;
  visible: boolean;
}
export const Modal: FC<ModalProps> = (props) => {
  return createPortal(
    <ModalArea visible={props.visible} background={props.background}>
      <ModalBox padding={props.padding}>
        <Layout direction="column" gap={10}>
          {props.children}
        </Layout>
      </ModalBox>
    </ModalArea>,
    document.getElementById("modal-root")!
  );
};
