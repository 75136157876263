type QueryFn<T> = () => Promise<T>;

export const withCache = <T>(
  cacheKey: string,
  useQueryHook: () => [T | undefined, boolean]
) => {
  return (): [T | undefined, boolean] => {
    const storedData = localStorage.getItem(cacheKey) as T | null;

    const [data, isLoading] = useQueryHook();

    if (!isLoading && data) {
      localStorage.setItem(cacheKey, JSON.stringify(data));
    }

    try {
      // @ts-ignore
      return [data || JSON.parse(storedData), isLoading];
    } catch (e) {
      return [data, isLoading];
    }
  };
};
