import { Layout } from "./Layout";
import { modes } from "../utils/mode";
import React, { FC } from "react";
import { GenerationMode } from "../utils/types";

interface ModePickerProps {
  mode: GenerationMode;
  setMode: (mode: GenerationMode) => void;
}

export const ModePicker: FC<ModePickerProps> = (props) => {
  return (
    <Layout
      role="group"
      gap={20}
      direction="column"
      style={{ padding: "20px" }}
    >
      {modes.map((m) => (
        <label key={m}>
          <input
            type="radio"
            value={m}
            checked={m === props.mode}
            onChange={() => props.setMode(m)}
            style={{ marginRight: "10px" }}
          />
          <span>{m}</span>
        </label>
      ))}
    </Layout>
  );
};
