import { IChore } from "./types";
import { getDayStatus, now } from "./date";

export const concludeVisible = (sealed: boolean, day: string) => {
  if (sealed) {
    return false;
  }

  const isEvening = now().getHours() >= 18;
  const dayStatus = getDayStatus(day, now());

  if (dayStatus === "past") {
    return true;
  } else if (dayStatus === "current") {
    return isEvening;
  } else {
    return false;
  }
};

export const siftOaths = <T extends { isOath: boolean }>(items: T[]) => {
  const oaths = items.filter((item) => item.isOath);
  const rest = items.filter((item) => !item.isOath);

  return { oaths, rest };
};

export const anyOathDone = (chores: IChore[]) => {
  return chores
    .filter((chore) => chore.isOath)
    .some((chore) => {
      return chore.entries.some((entry) => entry.done);
    });
};
