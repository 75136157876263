import { createContext, useContext } from "react";
import { IModalContext, ISettings } from "./types";

export const SettingsContext = createContext<ISettings>({
  autoGenerate: false,
});

export const useSettings = () => {
  return useContext(SettingsContext);
};

export const ModalContext = createContext<IModalContext>({
  _visible: false,
  _content: null,
  _config: {},
  _setConfig: () => {},
  _onYes: () => {},
  _onNo: () => {},
  open: () => Promise.resolve(false),
  close: () => {},
});
