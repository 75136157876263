import { isSameDay, isToday } from "date-fns";
import { QuestDto, TagDto } from "../dtos";
import { isDueNextDay, isDueThisDay, isOverdue } from "../../common/util/date";
import { ALL_TAG } from "./tags";

export class TaskUtil {
  tasks: QuestDto[];

  constructor(tasks: QuestDto[]) {
    this.tasks = tasks;
  }

  count() {
    return this.tasks.length;
  }

  isEmpty() {
    return this.count() === 0;
  }

  get() {
    return this.tasks;
  }

  private filter(filter: (task: QuestDto) => boolean) {
    return new TaskUtil(this.tasks.filter(filter));
  }

  private push(task: QuestDto) {
    this.tasks.push(task);
  }

  private filterAndRes(filter: (task: QuestDto) => boolean) {
    const [filtered, rest] = this.tasks.reduce<[QuestDto[], QuestDto[]]>(
      (acc, task) => {
        const [filtered, rest] = acc;
        if (filter(task)) {
          filtered.push(task);
        } else {
          rest.push(task);
        }
        return acc;
      },
      [[], []]
    );
    return [new TaskUtil(filtered), new TaskUtil(rest)];
  }

  private sort(sort: (a: QuestDto, b: QuestDto) => number) {
    return new TaskUtil([...this.tasks].sort(sort));
  }

  byTag(tagId: string | null, activeTags: TagDto[]) {
    if (!tagId || tagId === ALL_TAG) {
      return this.filter(Boolean);
    }
    // } else if (tag === NONE_TAG) {
    //   return this.filter((task) => mapTags(task.tags, activeTags).length === 0);
    // } else {
    return this.filter((task) => task.tags.includes(tagId));
  }

  newPriorities(tagImportantTag?: string) {
    const prioritised = this.tasks.reduce(
      (accumulator, task) => {
        if (isDueThisDay(task) || isOverdue(task)) {
          accumulator.due.push(task);
        } else if (task.priority === "inbox") {
          accumulator.inbox.push(task);
        } else if (task.priority === "important") {
          accumulator.important.push(task);
        } else if (task.priority === "planned") {
          accumulator.planned.push(task);
        } else if (task.priority === "to-be-planned") {
          accumulator.toBePlanned.push(task);
        }
        return accumulator;
      },
      {
        due: taskUtil(),
        inbox: taskUtil(),
        important: taskUtil(),
        planned: taskUtil(),
        toBePlanned: taskUtil(),
      }
    );

    const tagImportant = this.filter(
      (task) => task.priority === "tag-important"
    );

    tagImportant.tasks.forEach((task) => {
      if (tagImportantTag && task.tags.includes(tagImportantTag)) {
        prioritised.important.tasks.push(task);
      } else {
        prioritised.planned.tasks.push(task);
      }
    });

    return prioritised;
  }

  openAndDone() {
    return {
      open: this.open(),
      done: this.done(),
    };
  }

  open() {
    return this.filter((task) => !task.done);
  }

  important() {
    return this.filter((task) => task.priority === "important");
  }

  tagImportant() {
    return this.filter((task) => task.priority === "tag-important");
  }

  planned() {
    return this.filter((task) => task.priority === "planned");
  }

  toBePlanned() {
    return this.filter((task) => task.priority === "to-be-planned");
  }

  inbox() {
    return this.filter((task) => task.priority === "inbox");
  }

  countReport() {
    const open = this.open();
    return [
      open.important().count() +
        open.tagImportant().count() +
        open.planned().count() +
        open.inbox().count(),
      open.toBePlanned().count(),
    ] as const;
  }

  done() {
    return this.filter((task) => task.done);
  }

  doneToday() {
    return this.done().filter((task) => {
      return isToday(task.doneAt!);
    });
  }

  isPlannedOn(date: Date) {
    return this.filter((task) => {
      if (!task.dueBy) {
        return false;
      }

      const dueNow = isSameDay(date.getTime(), task.dueBy);
      const dueBefore = task.dueBy < date.getTime();

      if (task.done) {
        return dueNow;
      }

      return dueNow || dueBefore;
    });
  }

  sortByLastUpdated() {
    return this.sort((a, b) => {
      const aUpdatedAt = a.updatedAt || a.createdAt;
      const bUpdatedAt = b.updatedAt || b.createdAt;

      if (aUpdatedAt && bUpdatedAt) {
        return bUpdatedAt - aUpdatedAt;
      } else if (aUpdatedAt) {
        return -1;
      } else if (bUpdatedAt) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  collectTags(maxTags: number) {
    const tags = new Set<string>();
    for (const task of this.tasks) {
      if (tags.size > maxTags - 1) {
        break;
      }

      for (const tag of task.tags) {
        tags.add(tag);
      }
    }
    return Array.from(tags);
  }
}

export const taskUtil = (tasks: QuestDto[] = []) => {
  return new TaskUtil([...tasks]);
};

export const getStaleDays = (task: QuestDto) => {
  return Math.floor(
    (Date.now() - task.updatedPriorityAt) / (1000 * 60 * 60 * 24)
  );
};

export enum DUE_COLOR {
  TODAY = "red",
  TOMORROW = "orange",
  OVERDUE = "#9717F0",
  FUTURE = "green",
}

export const dueColor = (dueBy: number, time = Date.now()) => {
  if (isDueThisDay({ dueBy }, time)) {
    return DUE_COLOR.TODAY;
  }
  if (isDueNextDay({ dueBy }, time)) {
    return DUE_COLOR.TOMORROW;
  }
  if (isOverdue({ dueBy }, time)) {
    return DUE_COLOR.OVERDUE;
  }
  return DUE_COLOR.FUTURE;
};
