import { JikanCalendar } from "./JikanCalendar";
import { useJikanTasks } from "./useTasks";
import { useJikanCalendar } from "./useCalendar";

export function Jikan() {
  const calendar = useJikanCalendar();
  const tasks = useJikanTasks();

  return <JikanCalendar events={[...calendar, ...tasks]} />;
}
