import { isSameDay, isToday, isYesterday, subDays } from "date-fns";
import { ALL_TAG, NONE_TAG } from "./tags";
import { QuestDto } from "../dtos";

export const taskByDone = (tasks: QuestDto[]) => {
  return tasks.reduce<{
    done: QuestDto[];
    open: QuestDto[];
  }>(
    (acc, task) => {
      if (task.done) {
        acc.done.push(task);
        return acc;
      }
      acc.open.push(task);
      return acc;
    },
    { done: [], open: [] }
  );
};

export const tasksByTag = (tasks: QuestDto[], tag: string) => {
  if (tag === ALL_TAG) {
    return tasks;
  }
  if (tag === NONE_TAG) {
    return tasks.filter((task) => task.tags.length === 0);
  }

  return tasks.filter((task) => task.tags.includes(tag));
};

export const taskByDoneTime = (tasks: QuestDto[]) => {
  return tasks.reduce<{
    doneToday: QuestDto[];
    doneYesterday: QuestDto[];
    done2DaysAgo: QuestDto[];
    done3DaysAgo: QuestDto[];
    done4DaysAgo: QuestDto[];
    done5DaysAgo: QuestDto[];
    done6DaysAgo: QuestDto[];
    doneBefore: QuestDto[];
  }>(
    (acc, task) => {
      if (!task.doneAt) {
        return acc;
      }

      if (isToday(task.doneAt)) {
        acc.doneToday.push(task);
        return acc;
      }
      if (isYesterday(task.doneAt)) {
        acc.doneYesterday.push(task);
        return acc;
      }

      if (isSameDay(subDays(new Date(), 2), task.doneAt)) {
        acc.done2DaysAgo.push(task);
        return acc;
      }

      if (isSameDay(subDays(new Date(), 3), task.doneAt)) {
        acc.done3DaysAgo.push(task);
        return acc;
      }

      if (isSameDay(subDays(new Date(), 4), task.doneAt)) {
        acc.done4DaysAgo.push(task);
        return acc;
      }

      if (isSameDay(subDays(new Date(), 5), task.doneAt)) {
        acc.done5DaysAgo.push(task);
        return acc;
      }

      if (isSameDay(subDays(new Date(), 6), task.doneAt)) {
        acc.done6DaysAgo.push(task);
        return acc;
      }

      acc.doneBefore.push(task);
      return acc;
    },
    {
      doneToday: [],
      doneYesterday: [],
      done2DaysAgo: [],
      done3DaysAgo: [],
      done4DaysAgo: [],
      done5DaysAgo: [],
      done6DaysAgo: [],
      doneBefore: [],
    }
  );
};
