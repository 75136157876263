import { useEffect } from "react";
import { Responsible } from "./types";
import { dateToString, stringToDate } from "./date";

const LAST_SEEN_RESPONSIBLE_KEY = "lastSeenResponsible";

const setLastSeenResponsible = (responsible: Responsible) => {
  localStorage.setItem(LAST_SEEN_RESPONSIBLE_KEY, responsible);
};

export const useSetLastSeenResponsible = (responsible: Responsible) => {
  useEffect(() => {
    setLastSeenResponsible(responsible);
  }, [responsible]);
};

export const getLastSeenResponsible = () => {
  return localStorage.getItem(LAST_SEEN_RESPONSIBLE_KEY) as Responsible | null;
};

const DEBUG_DATE_KEY = "debugDate";

export const setDebugDate = (date = new Date()) => {
  localStorage.setItem(DEBUG_DATE_KEY, dateToString(date));
  window.location.reload();
};

if (window.origin.includes("localhost")) {
  console.log(
    '%cDebug date is available on "window.setDebugDate"',
    "color: blue; font-size: 20px;"
  );
  (window as any).setDebugDate = setDebugDate;
}

export const getDebugDate = () => {
  const date = localStorage.getItem(DEBUG_DATE_KEY);
  if (date !== null) {
    return stringToDate(date);
  }
  return undefined;
};

export const clearDebugDate = () => {
  localStorage.removeItem(DEBUG_DATE_KEY);
  window.location.reload();
};

export const isFreeConclude = () => {
  return sessionStorage.getItem("forceConcludable") === "true";
};

export const setFreeConclude = (value = true) => {
  sessionStorage.setItem("forceConcludable", value ? "true" : "false");
};

if (window.origin.includes("localhost")) {
  console.log(
    '%cFree conclude: "window.setFreeConclude"',
    "color: blue; font-size: 20px;"
  );
  (window as any).setFreeConclude = setFreeConclude;
}
