import { Anchor, AnchorProps, Box } from "grommet";
import { FC } from "react";
import { LinkProps } from "react-router-dom";

import { LinkKen } from "../navigation";

interface MyLinkProps extends LinkProps {
  center?: boolean;
  size?: AnchorProps["size"];
}

export const MyLink: FC<MyLinkProps> = (props) => {
  const { children, center, size, ...rest } = props;

  const link = (
    <LinkKen {...rest}>
      <Anchor size={size}>{children}</Anchor>
    </LinkKen>
  );

  if (center) {
    return <Box align="center">{link}</Box>;
  }

  return link;
};
