import { Box, Page, Text } from "grommet";
import { FC } from "react";
import { MyLink } from "../components/MyLink";
import { TaskForm } from "../components/TaskForm";
import { useCreateTaskWithQueue } from "../util/queries";
import { tagParam } from "../util/tags";
import { useGlobal } from "../global";

import { useNavigateKen } from "../navigation";

export const TaskAddPage: FC = () => {
  const navigate = useNavigateKen();
  const { currentTag } = useGlobal();

  const [createTask] = useCreateTaskWithQueue();
  const backUrl = "/" + tagParam(currentTag);

  return (
    <Page gap="large" pad="medium">
      <Text size="large" color="brand" textAlign="center">
        Dodaj taska
      </Text>
      <TaskForm
        defaultTag={currentTag}
        onSubmit={async (task) => {
          await createTask(task);
          navigate(backUrl);
        }}
      />
      <Box align="center">
        <MyLink to={backUrl}>Wróć</MyLink>
      </Box>
    </Page>
  );
};
