import React, { FC } from "react";
import { useParams } from "react-router";
import { ChoresTable } from "../components/ChoresTable";
import { Layout } from "../components/Layout";
import { Loader } from "../components/Loader";
import { dateToString, getDayStatus, now } from "../utils/date";
import { useChoresQuery } from "../utils/queries";
import { Responsible } from "../utils/types";
import { siftOaths } from "../utils/oaths";
import { promptMode } from "../utils/mode";
import { KenZone } from "../components/day/KenZone";
import { MetaZone } from "../components/day/MetaZone";
import { useGeneration } from "../utils/generation";
import { NavigationLinks } from "../components/NavigationLinks";
import { LinkOta } from "../navigation";
import { PenanceZone } from "../components/day/PenanceZone";

interface ChoresPageParams {
  day: string;
  responsible: Responsible;

  [key: string]: string;
}

export const ChoresPage: FC<{ compact?: boolean }> = ({ compact }) => {
  const { day = dateToString(now()), responsible = "all" } =
    useParams<ChoresPageParams>();

  const [chores, choresLoading] = useChoresQuery(day);

  const dayStatus = getDayStatus(day, now());

  const { rest: showedChores, oaths } = siftOaths(
    (chores || []).filter(
      (chore) => responsible === "all" || chore.responsible === responsible
    )
  );

  const {
    isGeneratedLoading,
    generateLoading,
    deleteGenerationLoading,
    canGenerate,
    isGenerated,
    modePicker,
    generate,
    setMode,
    deleteGeneration,
    autoGenerate,
  } = useGeneration(day);

  const tableLoading = choresLoading || isGeneratedLoading;

  if (generateLoading || deleteGenerationLoading) {
    return <Loader />;
  }

  if (compact) {
    return (
      <Layout gap={10} direction="column">
        <ChoresTable chores={showedChores} dayStatus={dayStatus} />
        <LinkOta to={"/"} target={"_blank"}>
          Full view
        </LinkOta>
      </Layout>
    );
  }

  return (
    <Layout gap={10} direction="column" width={600} center>
      <MetaZone day={day} oaths={oaths} />
      <KenZone day={day} />
      {tableLoading ? (
        <Loader />
      ) : (
        <>
          <ChoresTable chores={showedChores} dayStatus={dayStatus} />
          {modePicker}
        </>
      )}
      <div />
      <iframe
        src="https://calendar.google.com/calendar/embed?height=200&wkst=2&ctz=Europe%2FWarsaw&bgcolor=%23C0CA33&mode=AGENDA&showNav=0&showTitle=0&showPrint=0&showTabs=0&showTz=0&showCalendars=0&showDate=0&src=Z2R6aWVnZWxld3NraUBnbWFpbC5jb20&src=ZW4ucG9saXNoI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=cGwucG9saXNoI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%237986CB&color=%230B8043"
        style={{ borderWidth: 0, maxWidth: "100%" }}
        width="800"
        height="200"
        frameBorder="0"
        scrolling="no"
      ></iframe>
      <PenanceZone />

      {canGenerate && (
        <button type="button" onClick={() => generate()}>
          Wygeneruj dla tego dnia
        </button>
      )}
      {isGenerated && (
        <button
          type="button"
          onClick={() => {
            const promptedMode = promptMode();
            if (promptedMode) {
              setMode(promptedMode);
              deleteGeneration();
            }
          }}
        >
          Usuń wygenerowane ☠️ {autoGenerate && <>i wygeneruj ponownie</>}
        </button>
      )}
      <div />
      <NavigationLinks />
    </Layout>
  );
};
