// Interfaces for non-standard types
interface TokenResponse {
  error?: string;
  access_token?: string;
}

interface TokenClient {
  requestAccessToken: (options: { prompt: string }) => void;
  callback: (resp: TokenResponse) => void;
}

interface GapiCalendarEvent {
  summary: string;
  start: {
    dateTime?: string;
    date?: string;
  };
}

interface GapiCalendarEventListResponse {
  items: GapiCalendarEvent[];
}

interface GapiCalendarEventsListParameters {
  calendarId: string;
  timeMin: string;
  showDeleted: boolean;
  singleEvents: boolean;
  maxResults: number;
  orderBy: string;
}

const API_KEY: string | undefined = process.env.REACT_APP_API_KEY;
const CLIENT_ID: string | undefined = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Discovery doc URL for APIs used by the quickstart
const DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = "https://www.googleapis.com/auth/calendar.readonly";

let tokenClient: TokenClient | undefined;
let gapiInited = false;
let gisInited = false;

function waitForGapiAndGis(): Promise<void> {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (gapiInited && gisInited) {
        clearInterval(interval);
        resolve();
      }
    }, 100);
  });
}

/**
 * Callback after api.js is loaded.
 */
function gapiLoaded(): void {
  gapi.load("client", initializeGapiClient);
}

/**
 * Callback after the API client is loaded. Loads the
 * discovery doc to initialize the API.
 */
async function initializeGapiClient(): Promise<void> {
  await gapi.client.init({
    apiKey: API_KEY,
    discoveryDocs: [DISCOVERY_DOC],
  });
  gapiInited = true;
  maybeEnableButtons();
}

/**
 * Callback after Google Identity Services are loaded.
 */
function gisLoaded(): void {
  // @ts-ignore
  tokenClient = google.accounts.oauth2.initTokenClient({
    client_id: CLIENT_ID as string, // Type assertion since CLIENT_ID can be undefined
    scope: SCOPES,
    callback: "", // defined later
  });
  gisInited = true;
  maybeEnableButtons();
}

/**
 * Enables user interaction after all libraries are loaded.
 */
function maybeEnableButtons(): void {
  if (gapiInited && gisInited) {
    const authorizeButton = document.getElementById("authorize_button");
    if (authorizeButton) {
      authorizeButton.style.visibility = "visible";
    }
  }
}

/**
 * Sign in the user upon button click.
 */
export function handleAuthClick() {
  return new Promise((resolve) => {
    if (tokenClient) {
      tokenClient.callback = async (resp: TokenResponse) => {
        if (resp.error !== undefined) {
          throw resp;
        }

        resolve(resp);
      };

      if (gapi.client.getToken() === null) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        tokenClient.requestAccessToken({ prompt: "consent" });
      } else {
        // Skip display of account chooser and consent dialog for an existing session.
        tokenClient.requestAccessToken({ prompt: "" });
      }
    }
  });
}

/**
 * Sign out the user upon button click.
 */
function handleSignoutClick(): void {
  const token = gapi.client.getToken();
  if (token !== null) {
    // @ts-ignore
    google.accounts.oauth2.revoke(token.access_token);
    gapi.client.setToken(null);
  }
}

/**
 * Print the summary and start datetime/date of the next ten events in
 * the authorized user's calendar. If no events are found an
 * appropriate message is printed.
 */
export async function listUpcomingEvents(): Promise<GapiCalendarEvent[]> {
  try {
    const request: GapiCalendarEventsListParameters = {
      calendarId: "primary",
      timeMin: new Date().toISOString(),
      showDeleted: false,
      singleEvents: true,
      maxResults: 10,
      orderBy: "startTime",
    };

    const response: GapiCalendarEventListResponse =
      // @ts-ignore
      await gapi.client.calendar.events.list(request);

    console.log({ response });

    // @ts-ignore
    return response.result.items || [];
  } catch (err: any) {
    return [];
  }
}

function loadScript(src: string, onload: () => void): void {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  script.defer = true;
  script.onload = onload;
  document.body.appendChild(script);
}

// Function to initialize the Google API client
function loadGapiScript(): void {
  loadScript("https://apis.google.com/js/api.js", gapiLoaded);
}

// Function to initialize the Google Identity Services
function loadGisScript(): void {
  loadScript("https://accounts.google.com/gsi/client", gisLoaded);
}

// Load both scripts when needed (you can call this when the page loads or at some specific point)
function initializeGoogleApis(): void {
  loadGapiScript(); // Load Google API Client
  loadGisScript(); // Load Google Identity Services
}

// Call this function to initialize everything
initializeGoogleApis();

export async function loadEvents() {
  console.log("waitForGapiAndGis...");
  await waitForGapiAndGis();
  console.log("handleAuthClick...");
  await handleAuthClick();
  console.log("listUpcomingEvents...");
  return listUpcomingEvents();
}
