import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { Api } from "./api";
import { IMeta, IPlan, ISettings } from "./types";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { withCache } from "../../common/util/cache";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24 * 30,
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
});

export const PLANS_ALL_KEY = "PLAN_QUERY";
export const PLAN_KEY = "PLAN_KEY";
export const CHORES_KEY = "CHORES_QUERY";
export const GENERATE_KEY = "GENERATE_QUERY";
export const META_KEY = "META_QUERY";
export const PENANCE_KEY = "PENANCE_QUERY";
export const SETTINGS_KEY = "SETTINGS_KEY";

export const useChoresQuery = (day: string) => {
  const { data, isLoading } = useQuery([CHORES_KEY, day], async () => {
    if (!day) {
      return [];
    }
    return Api.choreController.getChores(day);
  });

  return [data, isLoading] as const;
};

export const useGenerationQuery = (day: string) => {
  const { data, isLoading } = useQuery([GENERATE_KEY, day], async () => {
    if (!day) {
      return null;
    }
    return Api.choreController.getGeneration(day);
  });

  return [data, isLoading] as const;
};

export const useMetaQuery = (day: string) => {
  const { data, isLoading } = useQuery([META_KEY, day], async () => {
    if (!day) {
      return null;
    }
    return Api.choreController.getMeta(day);
  });

  return [data, isLoading] as const;
};

export const usePenanceQuery = () => {
  const { data, isLoading } = useQuery([PENANCE_KEY], async () => {
    return Api.choreController.getPenance("2024-07-29");
  });

  return [data, isLoading] as const;
};

export const useMonthMetaQuery = (month: string) => {
  const { data, isLoading } = useQuery([META_KEY, month], async () => {
    if (!month) {
      return null;
    }
    return Api.choreController.getMonthMeta(month);
  });

  return [data, isLoading] as const;
};

export const useAllPlansQuery = () => {
  const { data: plans, isLoading } = useQuery([PLANS_ALL_KEY], () => {
    return Api.planController.getAllPlans();
  });
  return { plans, isLoading };
};

export const usePlanQuery = (id?: string) => {
  const { data, isLoading } = useQuery([PLAN_KEY, id], () => {
    if (!id) return null;
    return Api.planController.getPlanById(id);
  });
  return { plan: data, isLoading };
};

export const useAddPlan = () => {
  const { mutate } = useMutation(
    async (plan: Omit<IPlan, "_id">) => {
      await Api.planController.addPlan(plan);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PLANS_ALL_KEY]);
      },
    }
  );

  return mutate;
};

export const useUpdatePlan = () => {
  const { mutate } = useMutation(
    async (plan: IPlan) => {
      await Api.planController.updatePlan(plan);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PLANS_ALL_KEY]);
      },
    }
  );

  return mutate;
};

export const useDeletePlan = () => {
  const { mutate } = useMutation(
    async (id: string) => {
      await Api.planController.deletePlan(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PLANS_ALL_KEY]);
      },
    }
  );

  return mutate;
};

export const useGenerateChores = (day: string, mode: string) => {
  const { mutate, isLoading } = useMutation(
    async () => {
      await Api.choreController.generate(day, mode);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CHORES_KEY]);
        queryClient.invalidateQueries([GENERATE_KEY]);
      },
    }
  );
  return [mutate, isLoading] as const;
};

export const useToggleChoreStatus = () => {
  const { mutate } = useMutation(
    async ({
      choreId,
      entryId,
      sharedId,
    }: {
      choreId: string;
      entryId: string;
      sharedId?: string;
    }) => {
      await Api.choreController.toggleChoreStatus(choreId, entryId, sharedId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CHORES_KEY]);
      },
    }
  );

  return mutate;
};

export const useDeleteGeneration = (day: string) => {
  const { mutate, isLoading } = useMutation(
    async () => {
      await Api.choreController.deleteGeneration(day);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CHORES_KEY]);
        queryClient.invalidateQueries([GENERATE_KEY]);
      },
    }
  );
  return [mutate, isLoading] as const;
};

export const useSettingsQuery = withCache("settings", () => {
  const { data, isLoading } = useQuery([SETTINGS_KEY], () => {
    return Api.settingsController.getSettings();
  });
  return [data, isLoading];
});

export const useUpdateSettings = () => {
  const { mutate } = useMutation(
    async (settings: ISettings) => {
      await Api.settingsController.updateSettings(settings);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SETTINGS_KEY]);
      },
    }
  );

  return mutate;
};

export const useUpdateMeta = () => {
  const { mutate } = useMutation(
    async (meta: IMeta) => {
      await Api.choreController.updateMeta(meta);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([META_KEY]);
        queryClient.invalidateQueries([PENANCE_KEY]);
      },
    }
  );

  return mutate;
};
