import styled, { css } from "styled-components";
import { LinkOta } from "../navigation";
import { FC } from "react";

const Styled = styled(LinkOta)<{ highlighted?: boolean }>`
  margin-left: 20px;
  font-size: 1.7em !important;
  text-decoration: none;
  ${(props) =>
    props.highlighted &&
    css`
      color: #78eb93;
      font-weight: bold;
    `}
`;

interface WriteLinkProps {
  day: string;
  written?: string;
  back?: boolean;
}

export const WriteLink: FC<WriteLinkProps> = (props) => {
  const { day, written, back } = props;
  const somethingWritten = (written?.length || 0) > 10;

  const [suffix, antiSuffix] = !back ? ["", "write"] : ["write", ""];
  const writeUrl = `/all/${day}/${antiSuffix}`;

  return (
    <Styled to={writeUrl} highlighted={somethingWritten}>
      {back ? "⤺" : "✍︎"}
    </Styled>
  );
};
