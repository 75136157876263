import { Box, Button, Page } from "grommet";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "react-router-dom";
import { useGlobal } from "../global";
import { useUpdateTag } from "../util/queries";
import { tagParam } from "../util/tags";
import { TagDto } from "../dtos";

import { LinkKen, useNavigateKen } from "../navigation";

export const TagEditPage: FC = () => {
  const { tag } = useParams<{ tag: string }>();
  const { tags } = useGlobal();
  const [updateTag] = useUpdateTag();
  const navigate = useNavigateKen();

  const tagDto = tags.find((tg) => tg.tagId === tag);

  const { reset, register, handleSubmit } = useForm<TagDto>();

  useEffect(() => {
    if (tagDto) {
      reset(tagDto);
    }
  }, [tagDto]);

  if (!tagDto) {
    return null;
  }

  return (
    <Page gap="medium">
      <Box align="center">
        <LinkKen to={"/tags"}>Lista wszystkich tagów</LinkKen>
      </Box>
      <Form
        onSubmit={handleSubmit(async (dto) => {
          await updateTag(dto);
          if (dto.active) {
            navigate(tagParam(dto.tagId));
          } else {
            navigate("/");
          }
        })}
      >
        <Box direction="column" gap="medium" align="center">
          <label>
            Name
            <input type="text" {...register("name")} />
          </label>

          <label>
            Icon
            <input type="text" {...register("icon")} />
          </label>

          <label>
            Kolor
            <input type="color" {...register("color")} />
          </label>

          <label>
            Aktywny
            <input type="checkbox" {...register("active")} />
          </label>

          <Box align="center">
            <Button type="submit" label="Zapisz" />
          </Box>
        </Box>
      </Form>
    </Page>
  );
};
