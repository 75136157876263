import React from "react";
import { DonePage } from "./pages/DonePage";
import { TagEditPage } from "./pages/TagEditPage";
import { TagsPage } from "./pages/TagsPage";
import { TaskAddPage } from "./pages/TaskAddPage";
import { TaskEditPage } from "./pages/TaskEditPage";
import { TasksPage } from "./pages/TasksPage";

export const kenRoutes = [
  {
    path: "/",
    element: <TasksPage />,
  },
  {
    path: "/embedded",
    element: <TasksPage embedded />,
  },
  {
    path: "/done",
    element: <DonePage />,
  },
  {
    path: "/add",
    element: <TaskAddPage />,
  },
  {
    path: "/edit/:id",
    element: <TaskEditPage />,
  },
  {
    path: "/tags",
    element: <TagsPage />,
  },
  {
    path: "/tags/:tag",
    element: <TagEditPage />,
  },
];
