import { AddCircle } from "grommet-icons";
import { MyLink } from "./MyLink";
import React, { FC } from "react";
import { ALL_TAG, tagParam, useMapTags } from "../util/tags";

interface AddTaskButtonProps {
  tagName?: string;
  onClick?: () => void;
}

export const AddTaskButton: FC<AddTaskButtonProps> = (props) => {
  const { tagName = ALL_TAG } = props;
  const tag = useMapTags([tagName])[0];
  const color = tag?.color || "white";

  return (
    <MyLink
      to={"/add" + tagParam(tagName)}
      center
      onClick={(e) => {
        if (props.onClick) {
          e.preventDefault();
          props.onClick();
        }
      }}
    >
      <AddCircle size="large" color={color} />
    </MyLink>
  );
};
