import React, { FC } from "react";
import { useMetaQuery } from "../../utils/queries";
import { ConcludeDay } from "./ConcludeDay";
import { IChore } from "../../utils/types";
import { anyOathDone, concludeVisible } from "../../utils/oaths";
import { DayNavigation } from "./DayNavigation";

interface MetaZoneProps {
  day: string;
  oaths: IChore[];
}

export const MetaZone: FC<MetaZoneProps> = (props) => {
  const { day, oaths } = props;

  const [meta, metaLoading] = useMetaQuery(day);

  const haOaths = !!oaths.length;
  const sealed = anyOathDone(oaths || []);

  const enabled = haOaths && concludeVisible(sealed, day);

  return (
    <>
      <DayNavigation day={day} written={meta?.written} />
      {meta && (
        <ConcludeDay
          day={day}
          oaths={oaths}
          enabled={enabled}
          meta={meta}
          key={day}
        />
      )}
    </>
  );
};
