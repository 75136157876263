import { createPortal } from "react-dom";
import { FC, PropsWithChildren, ReactNode } from "react";
import { Box, Layer } from "grommet";

interface ModalV2Props {
  children: ReactNode;
  onClickOutside?: () => void;
}

export const Modal: FC<ModalV2Props> = (props) => {
  return (
    <ModalPortal>
      <Layer
        onClickOutside={props.onClickOutside}
        animation="fadeIn"
        position="center"
      >
        <Box fill style={{ minWidth: "378px" }}>
          {props.children}
        </Box>
      </Layer>
    </ModalPortal>
  );
};

const ModalPortal = ({ children }: PropsWithChildren) => {
  return createPortal(children, document.getElementById("modal-root")!);
};
