import React, { FC } from "react";
import { Layout } from "../components/Layout";
import { PlanForm } from "../components/PlanForm";
import { useAddPlan } from "../utils/queries";

import { LinkOta, useNavigateOta } from "../navigation";

export const PlanOrOathAddPage: FC<{ type: "plan" | "oath" }> = ({ type }) => {
  const navigate = useNavigateOta();

  const addPlan = useAddPlan();

  const isOath = type === "oath";
  const backLink = type === "oath" ? "/oath" : "/plan";

  return (
    <Layout direction="column" gap={20}>
      <PlanForm
        submitText={`Dodaj pakt`}
        onSubmit={addPlan}
        onSuccess={() => navigate(backLink)}
        oathForm={isOath}
      />
      <LinkOta to={backLink} link>
        Anuluj
      </LinkOta>
    </Layout>
  );
};
