import { Box } from "grommet";
import React, { FC, useEffect } from "react";
import { BigLoader } from "../components/BigLoader";
import { CollapseBox } from "../components/CollapseBox";
import { PageWithControls } from "../components/PageWithControls";
import { TaskList } from "../components/TaskList";
import { taskByDoneTime } from "../util/filter";
import { isSpecialTag } from "../util/tags";
import { useGlobal } from "../global";
import { taskUtil } from "../util/task";
import { MyLink } from "../components/MyLink";
import useRememberScrollPosition from "../util/scroll-position";

interface TasksPageProps {
  embedded?: boolean;
}

export const TasksPage: FC<TasksPageProps> = ({ embedded }) => {
  const { tasks, tasksLoading, currentTag, activeTags } = useGlobal();

  const { open, done } = taskUtil(tasks).openAndDone();

  const tagOpen = open.byTag(currentTag, activeTags);
  const tagDone = done.byTag(currentTag, activeTags);

  const { doneToday } = taskByDoneTime(open.get());

  const currentTagIsSpecial = isSpecialTag(currentTag);

  const showDone = !currentTagIsSpecial && tagDone.count() > 0;

  const Wrapper = embedded ? Box : PageWithControls;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentTag]);

  useRememberScrollPosition("tasks-page");

  return (
    <Wrapper done={doneToday.length}>
      {!tasks.length ? (
        <BigLoader />
      ) : (
        <>
          <Box margin={{ vertical: "20px" }} direction="row" gap="medium">
            <MyLink to={`/tags`}>Tagi</MyLink>

            {!currentTagIsSpecial && (
              <MyLink to={`/tags/${currentTag}`}>Edytuj tag</MyLink>
            )}
          </Box>
          <TaskList
            tasks={tagOpen.get()}
            versatile={embedded}
            tagImportantTag={currentTag}
          />

          {showDone && (
            <Box margin={{ top: "large" }}>
              <CollapseBox showText={`Pokaż zrobione (${tagDone.count()})`}>
                <TaskList
                  tasks={tagDone.get()}
                  label={`Zrobione dla taga (${tagDone.count()})`}
                />
              </CollapseBox>
            </Box>
          )}
        </>
      )}
    </Wrapper>
  );
};
