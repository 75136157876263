import React, { FC, ReactNode, useState } from "react";
import { ModalContext } from "../utils/context";
import { ModalConfig } from "../utils/types";

export const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState<ReactNode>(null);
  const [onYes, setOnYes] = useState<(() => void) | undefined>(undefined);
  const [onNo, setOnNo] = useState<(() => void) | undefined>(undefined);
  const [config, setConfig] = useState<ModalConfig>({});

  const open = (newContent: ReactNode, config?: ModalConfig) => {
    setContent(newContent);
    setConfig(config || {});
    setVisible(true);
    const promise = new Promise<boolean>((resolve) => {
      setOnYes(() => () => {
        resolve(true);
        close();
      });
      setOnNo(() => () => {
        resolve(false);
        close();
      });
    });

    return promise;
  };

  const close = () => {
    setVisible(false);
    setOnYes(undefined);
    setOnNo(undefined);
    setTimeout(() => {
      setContent(null);
    }, 500);
  };

  return (
    <ModalContext.Provider
      value={{
        _visible: visible,
        _content: content,
        _config: config,
        _setConfig: setConfig,
        _onYes: onYes,
        _onNo: onNo,
        open,
        close,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
