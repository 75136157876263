import React, { FC } from "react";
import { TaskItem } from "./TaskItem";
import { useToggleDone } from "../util/queries";
import { Box, Grid, RadioButton } from "grommet";
import { QuestDto } from "../dtos";
import styled from "styled-components";
import { usePersistedState } from "../../common/util/react";
import { applyOrder, getOrderIcon, Order, orderOptions } from "../util/order";

const GroupWrapper = styled.div`
  width: 100%;
`;

const OrderWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 5px 0 10px;
`;

const Gridd = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
`;

interface TaskGroupProps {
  tasks: QuestDto[];
  groupId: string;
  isOta?: boolean;
  order?: Order;
  time?: number;
}

export const TaskGroup: FC<TaskGroupProps> = (props) => {
  const { groupId, tasks, order, isOta, time } = props;
  const [toggleDone] = useToggleDone();

  const key = `order-${groupId}`;
  const [selectedOrder, setSelectedOrder] = usePersistedState<Order>(
    key,
    order || "newest"
  );

  const orderSelect = (
    <OrderWrapper>
      {orderOptions.map((option) => (
        <RadioButton
          value={option}
          name="key"
          key={option}
          label={getOrderIcon(option)}
          checked={selectedOrder === option}
          onChange={(e) => setSelectedOrder(option)}
        />
      ))}
    </OrderWrapper>
  );

  if (!tasks.length) {
    return null;
  }

  return (
    <GroupWrapper>
      {!order && orderSelect}
      <Gridd>
        {applyOrder(tasks, selectedOrder, key).map((task) => (
          <TaskItem
            task={task}
            onToggle={toggleDone}
            key={task._id}
            isOta={isOta}
            time={time}
          />
        ))}
      </Gridd>
    </GroupWrapper>
  );
};
