import { createGlobalStyle } from "styled-components";

export const AllColor = "rgb(65,62,62)";
export const KasiaColor = "#fe5f7b";
export const GrzesColor = "#40baf8";

export const tabletOrBigger = "@media (min-width: 768px)";

const regularFontSize = "18px";
const mobileFontSize = "16px";

export const oathImage = `url("/images/bones.avif")`;

export const GlobalStyle = createGlobalStyle`
  html {
    --main-color: ${AllColor};
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Helvetica, Sans-Serif;
    font-size: ${mobileFontSize};
    ${tabletOrBigger} {
      font-size: ${regularFontSize};
    }
  }

  main {
    max-width: 1200px;
    overflow-x: hidden;
    margin: 0 auto;
    padding: 10px;
    ${tabletOrBigger} {
      padding: 20px;
    }
  }

  button {
    all: unset;
    border: 1px solid var(--main-color);
    padding: 5px 10px;
    color: var(--main-color);
    transition: transform .1s;

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }

  input {
    padding: 3px 10px;
  }

  input, option, select, button {
    font-size: ${mobileFontSize};
    ${tabletOrBigger} {
      font-size: ${regularFontSize};
    }
  }

  a {
    color: var(--main-color);
    transition: transform .1s;


    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }

  p {
    line-height: 1.5;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    ${tabletOrBigger} {
      border: 1px solid #ddd;
    }
    font-size: 1em;
    line-break: loose;
  }

  td {
    padding: 7px 10px;
  }

  .color {
    color: var(--main-color);
  }

  .bigger {
    font-size: 1.2em;
  }

  .center {
    text-align: center;
  }

  .align-start {
    align-self: flex-start;
  }

  .bigger-link {
    font-size: 1.2em;
  }

  .conclude {
    background-image: ${oathImage};
    background-size: 20%;
    padding: 20px;
    
    //span {
    //    display: inline-block;
    //  background: white;
    //    padding: 10px;
    //  color: var(--main-color);
    //}
    
    color: white;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    letter-spacing: 5px;
  }
  
  .mode {
    padding-left: 20px;
    padding-right: 20px;
  }
  .mode.active {
      border: 2px solid black;
    }
  
  .oka-quests .task-item {
    padding: 5px;
    box-shadow: none;
    border-radius: unset;
  }
  
`;
