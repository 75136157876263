import { Box, Button, Page, Text } from "grommet";
import React, { FC } from "react";
import { MyLink } from "../components/MyLink";
import { useGlobal } from "../global";
import { useUpdateTag } from "../util/queries";
import styled from "styled-components";
import { LinkKen } from "../navigation";
import { TagDto } from "../dtos";
import { ZoomIn } from "grommet-icons";
import { tagParam } from "../util/tags";

const TagsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 300px));
  gap: 20px;
  margin: 20px 0;
`;

export const TagsPage: FC = () => {
  const { tags } = useGlobal();
  const [updateTag] = useUpdateTag();

  const mapTags = (tgs: TagDto[]) =>
    tgs.map((tag) => {
      const viewLink = tagParam(tag.tagId);
      const editLink = `tags/${tag.tagId}`;

      return (
        <MyLink
          key={tag.tagId}
          to={`/` + editLink}
          style={{ textDecoration: "none" }}
        >
          <Box
            direction="row"
            gap="small"
            pad="small"
            border
            align="center"
            justify="between"
          >
            <Text>
              {tag.name} {tag.icon}
            </Text>
            <Box direction="row" align="center" gap="small">
              <div
                style={{
                  backgroundColor: tag.color,
                  width: "40px",
                  height: "40px",
                  borderRadius: "20%",
                  display: "inline-block",
                }}
              />
              <Button
                icon={<ZoomIn size="medium" />}
                href={`/ken/` + viewLink}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Box>
          </Box>
        </MyLink>
      );
    });

  return (
    <Page>
      <h1>Tagi</h1>
      <TagsGrid>{mapTags(tags.filter((tag) => tag.active))}</TagsGrid>
      <h2>Inactive</h2>
      <TagsGrid>{mapTags(tags.filter((tag) => !tag.active))}</TagsGrid>
      <LinkKen to={"/"}>Back to main page</LinkKen>
    </Page>
  );
};
