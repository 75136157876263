import React, { FC } from "react";
import {
  Link,
  LinkProps,
  NavigateOptions,
  useNavigate,
} from "react-router-dom";
import { To } from "react-router";

interface LinkOtaProps extends LinkProps {
  link?: boolean;
}

export const LinkOta: FC<LinkOtaProps> = ({
  to,
  link,
  className = "",
  ...props
}) => {
  if (link) {
    className += " align-start bigger-link";
  }
  return <Link to={`/ota${to}`} {...props} className={className} />;
};
export const useNavigateOta = () => {
  const navigate = useNavigate();

  return (to: To, options?: NavigateOptions) => navigate(`/ota${to}`, options);
};
