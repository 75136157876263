import React, { FC } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { SettingsProvider } from "./ota/components/SettingsProvider";
import { GlobalStyle } from "./style";
import { queryClient } from "./ota/utils/queries";
import { DateDebugger } from "./ota/components/DateDebugger";
import { Confirm } from "./ota/components/Modal";
import { ModalProvider } from "./ota/components/ModalProvider";
import { otaRoutes } from "./ota/routes";
import { kenRoutes } from "./ken/routes";
import { GlobalContextProvider } from "./ken/global";
import { grommet, Grommet, ThemeType } from "grommet";
import { deepMerge } from "grommet/utils";
import { Home } from "./common/components/Home";
import { AppSwitcher } from "./common/components/AppSwitcher";
import { Jikan } from "./jikan";

const pre = (name: string, routes: RouteObject[]) =>
  routes.map((route) => ({
    ...route,
    path: `/${name}${route.path}`,
  }));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  ...pre("/ota", otaRoutes),
  ...pre("/ken", kenRoutes),
  {
    path: "/jikan",
    element: <Jikan />,
  },
]);

const theme: ThemeType = deepMerge(grommet, {
  global: {
    colors: {
      brand: "gray",
      second: "#dbd8e3",
      light: "rgba(99,196,243,0.15)",
      dark: "#79797d",
    },
    font: {
      family: "Roboto",
      size: "14px",
      height: "20px",
    },
  },
});
const App: FC = () => {
  return (
    <Grommet theme={theme}>
      <QueryClientProvider client={queryClient}>
        <GlobalContextProvider>
          <SettingsProvider>
            <ModalProvider>
              <main>
                <GlobalStyle />
                <DateDebugger />
                <RouterProvider router={router} />
                <Confirm />
              </main>
            </ModalProvider>
            <AppSwitcher />
          </SettingsProvider>
        </GlobalContextProvider>
      </QueryClientProvider>
    </Grommet>
  );
};

export default App;
