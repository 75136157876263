import { useSearchParams } from "react-router-dom";
import { useGlobal } from "../global";
import { TagDto } from "../dtos";

export const ALL_TAG = "all";
export const NONE_TAG = "none";

export const useCurrentTag = () => {
  const [searchParams] = useSearchParams();
  const currentTag = searchParams.get("tag") || ALL_TAG;
  return currentTag;
};

export const tagParam = (tag: string) => {
  return tag === ALL_TAG ? "" : `?tag=${tag}`;
};

export const isSpecialTag = (tag: string) => {
  return tag === ALL_TAG || tag === NONE_TAG;
};

export const useTagIdsFromNames = (tagNames: string[]) => {
  const { tags } = useGlobal();
  return tagNames
    .map((tagName) => {
      const foundTag = tags.find((tag) => tag.name === tagName);
      return foundTag ? foundTag.tagId : undefined;
    })
    .filter(Boolean) as string[];
};

export const useMapTags = (tagIds: string[]) => {
  const { activeTags } = useGlobal();
  return mapTags(tagIds, activeTags);
};

export const mapTags = (tagIds: string[], activeTags: TagDto[]) => {
  return tagIds.flatMap((tagId) => {
    const foundTag = activeTags.find((tag) => tag.tagId === tagId);
    if (foundTag) {
      return [foundTag];
    }
    return [];
  });
};

export const SPECIAL_TAG_POOL = "pool";
