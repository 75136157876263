import { FC } from "react";
import { DayStatus, IChore } from "../utils/types";
import { ChoresTableEntryCell } from "./ChoresTableEntryCell";
import { Layout } from "./Layout";
import {
  choreDisplayedName,
  ChoreTag,
  hasChoreTag,
  sortChores,
} from "../utils/chore";
import styled, { css } from "styled-components";
import { now } from "../utils/date";

export const WORK = "WORK";

const pairChores = (items: IChore[]): IChore[][] => {
  const singleEntryItems: IChore[] = [];
  const multiEntryItems: IChore[] = [];
  const result: IChore[][] = [];

  // Split items into single-entry and multi-entry
  items.forEach((item) => {
    if (item.entries.length > 1) {
      multiEntryItems.push(item);
    } else {
      singleEntryItems.push(item);
    }
  });

  // Add multi-entry items to result
  multiEntryItems.forEach((item) => result.push([item]));

  // Pair up single-entry items
  for (let i = 0; i < singleEntryItems.length; i += 2) {
    if (i + 1 < singleEntryItems.length) {
      result.push([singleEntryItems[i], singleEntryItems[i + 1]]);
    } else {
      // For an odd number of single-entry items, add the last one as an array
      result.push([singleEntryItems[i]]);
    }
  }

  return result;
};

interface ChoresTableProps {
  chores: IChore[];
  dayStatus?: DayStatus;
}

export const ChoresTable: FC<ChoresTableProps> = (props) => {
  const { chores, dayStatus } = props;

  const isWork = (chore: IChore) => hasChoreTag(chore.choreName, ChoreTag.WORK);

  const isWorkTime =
    dayStatus === "current" && now().getHours() >= 9 && now().getHours() < 15;
  const hasDueWork =
    chores.find(isWork)?.entries.some((entry) => !entry.done) ?? false;
  const workFocus = isWorkTime && hasDueWork;

  const isBlurred = (chore: IChore) => {
    return workFocus && !isWork(chore);
  };

  return (
    <Layout direction="column" gap={20}>
      <table>
        <tbody>
          {sortChores(chores).map((chore) => (
            <ChoreRow
              key={chore._id}
              chore={chore}
              blurred={isBlurred(chore)}
            />
          ))}
        </tbody>
      </table>
    </Layout>
  );
};

interface ChoreRowProps {
  chore: IChore;
  blurred: boolean;
}

const Tr = styled.tr<{ blurred: boolean }>`
  ${(props) =>
    props.blurred &&
    css`
      pointer-events: none;
      filter: blur(4px);
    `}
`;

const ChoreRow: FC<ChoreRowProps> = ({ chore, blurred }) => {
  const getColspan = (numberOfEntries: number) => {
    switch (numberOfEntries) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      case 4:
        return 3;
      default:
        return 12;
    }
  };
  return (
    <Tr blurred={blurred}>
      <td>
        <span
          onClick={() => {
            chore.details && alert(chore.details);
          }}
          style={{ cursor: chore.details ? "pointer" : "default" }}
        >
          {choreDisplayedName(chore.choreName)}
        </span>
      </td>
      {chore.entries.map((entry) => {
        return (
          <ChoresTableEntryCell
            key={entry._id}
            chore={chore}
            entry={entry}
            colSpan={getColspan(chore.entries.length)}
          />
        );
      })}
    </Tr>
  );
};
