import React, { FC } from "react";
import { dateToString, now } from "../utils/date";
import { getLastSeenResponsible } from "../utils/localStorage";

import { useNavigateOta } from "../navigation";

export const HomePage: FC = () => {
  const navigate = useNavigateOta();

  const currentDay = dateToString(now());

  React.useEffect(() => {
    const lastSeenResponsible = getLastSeenResponsible() || "all";
    navigate(`/${lastSeenResponsible}/${currentDay}`);
  });

  return null;
};
