import {
  useDeleteGeneration,
  useGenerateChores,
  useGenerationQuery,
} from "./queries";
import { usePersistedGenerationMode } from "./mode";
import { useSettings } from "./context";
import React, { useEffect } from "react";
import { getDayStatus, now } from "./date";
import { ModePicker } from "../components/ModePicker";

export const useGeneration = (day: string) => {
  const { autoGenerate } = useSettings();

  const dayStatus = getDayStatus(day, now());

  const [mode, setMode] = usePersistedGenerationMode(day);

  const [generation, isGeneratedLoading] = useGenerationQuery(day);
  const isGenerated = !!generation;

  const [generate, generateLoading] = useGenerateChores(day, mode);
  const [deleteGeneration, deleteGenerationLoading] = useDeleteGeneration(day);

  const canGenerate =
    dayStatus === "current" && !isGenerated && !isGeneratedLoading;
  const canAutoGenerate = canGenerate && autoGenerate;

  useEffect(() => {
    if (canAutoGenerate) {
      generate();
    }
  }, [canAutoGenerate, generate]);

  const modePicker = (
    <>
      {dayStatus === "future" && <ModePicker mode={mode} setMode={setMode} />}
    </>
  );

  return {
    isGeneratedLoading,
    generateLoading,
    deleteGenerationLoading,
    canGenerate,
    isGenerated,
    deleteGeneration,
    modePicker,
    generate,
    setMode,
    autoGenerate,
  };
};
