import { useGlobal } from "../ken/global";
import { JikanEvent } from "./JikanCalendar";

export const useJikanTasks = (): JikanEvent[] => {
  const { tasks } = useGlobal();

  return tasks
    .filter((task) => task.dueBy)
    .map((task) => {
      return {
        id: task._id,
        date: new Date(task.dueBy!),
        title: task.name,
        type: "quest",
      };
    });
};
