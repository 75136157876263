import styled, { css } from "styled-components";

interface LayoutProps {
  gap?: 0 | 5 | 10 | 20 | 40;
  direction?: "row" | "column";
  center?: boolean;
  width?: number;
}

export const Layout = styled.div<LayoutProps>`
  display: flex;

  max-width: ${(props) => (props.width ? `${props.width}px` : "100%")};

  gap: ${(props) => props.gap || 0}px;

  ${(props) =>
    props.center &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}

  ${(props) => {
    const { direction = "row", gap = 0 } = props;

    switch (direction) {
      case "row":
        return css`
          flex-direction: row;

          ${props.center &&
          css`
            align-items: center;
          `}
        `;
      case "column":
        return css`
          flex-direction: column;
        `;
    }
  }}
`;
