import { usePenanceQuery } from "../../utils/queries";
import styled from "styled-components";
import { getKcalColor } from "../../utils/kcal";
import { Info, Vulnerability } from "grommet-icons";

const StyledPenance = styled.div`
  display: flex;
  gap: 20px;
  padding: 10px;
  align-items: center;

  div {
    display: flex;
    gap: 10px;
  }
`;

const THE_RULES_URL =
  "https://docs.google.com/document/d/1UxLcvAe4-uQIuDGpPqChI1sLoi2blUb4teSfQaJvZLE/edit?usp=sharing";

export const PenanceZone = () => {
  const [penance, penanceLoading] = usePenanceQuery();

  if (penanceLoading) {
    return null;
  }

  const showPenanceInfo = () => {
    const { kcals, ...penanceRest } = penance as any;
    alert(JSON.stringify(penanceRest, undefined, 4));
  };

  return (
    <StyledPenance>
      <a href={THE_RULES_URL} target="_blank">
        The Rules
      </a>
      <div>
        <Vulnerability color={getKcalColor("fasting")} />
        <span> {penance?.fastsToDo}</span>
      </div>
      <div>
        <Vulnerability color={getKcalColor("loss")} />
        <span>{penance?.reductionsToDo}</span>
      </div>
      <div>
        <Info size="medium" onClick={showPenanceInfo} opacity={0.3} />
      </div>
    </StyledPenance>
  );
};
