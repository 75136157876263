import { addDays, format, isSameDay, isToday, isTomorrow } from "date-fns";
import { QuestDto } from "../../ken/dtos";

export const endOfTheDay = (date: Date) => {
  const end = new Date(date);
  end.setHours(23, 59, 59, 999);
  return end;
};

export const endOfToday = () => endOfTheDay(new Date());

export const endOfTomorrow = () =>
  endOfTheDay(new Date(Date.now() + 24 * 60 * 60 * 1000));

export const shortDate = (time: number) => {
  return format(time, "dd.MM.yy");
};

export const isDueThisDay = (
  questDto: Pick<QuestDto, "dueBy">,
  time = Date.now()
) => {
  return Boolean(questDto.dueBy && isSameDay(time, questDto.dueBy));
};

export const isDueNextDay = (
  questDto: Pick<QuestDto, "dueBy">,
  time = Date.now()
) => {
  return Boolean(questDto.dueBy && isSameDay(addDays(time, 1), questDto.dueBy));
};

export const isOverdue = (
  questDto: Pick<QuestDto, "dueBy">,
  time = Date.now()
) => {
  return Boolean(questDto.dueBy && questDto.dueBy < time);
};

export const isDueOrOverdue = (
  questDto: Pick<QuestDto, "dueBy">,
  time = Date.now()
) => {
  return isDueThisDay(questDto, time) || isOverdue(questDto, time);
};
