import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AddTaskButton } from "./AddTaskButton";
import { useGlobal } from "../global";
import styled, { css } from "styled-components";
import { useCreateTaskWithQueue } from "../util/queries";
import { Box, Button } from "grommet";
import { Close, PowerCycle } from "grommet-icons";
import {
  clearMarkers,
  getExtraMarker,
  getMarkerDueBy,
  getMarkerIcon,
  getMarkerPriority,
  getTags,
  useOnKey,
  useOnKeyWhenNoFocus,
  useQuickTags,
} from "../util/quick-form";
import { isMobile } from "../../common/util/react";
import { TagBar } from "./TagBar";

type QuickFormMode = "add" | "search";

const Input = styled.textarea<{
  quickFormMode: QuickFormMode;
  extended: boolean;
}>`
  flex: 1;
  font-size: 16px;
  width: 100%;
  padding: 15px 10px;

  ${({ extended }) =>
    extended
      ? css`
          transform: translateY(-50px);
        `
      : css``}

  ${({ quickFormMode }) =>
    quickFormMode === "search" &&
    css`
      background-color: #94bdf0;
    `}
`;

const Frm = styled.form`
  margin-bottom: 25px;
  flex: 1;
  margin-right: 25px;
  height: 30px;
  position: relative;
`;

const QuickTags = styled.div`
  position: absolute;
  left: 10px;
  top: 30px;
  width: 100px;
`;

const InfoIcon = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
`;

export const QuickForm: FC = () => {
  const { currentTag, setSearch } = useGlobal();
  const [createTask] = useCreateTaskWithQueue();

  const [quickFormMode, setQuickFormMode] = React.useState<"add" | "search">(
    "add"
  );

  const { register, handleSubmit, reset, setFocus, watch } = useForm({
    defaultValues: {
      input: "",
    },
  });

  const focusInput = () => {
    setTimeout(() => {
      document.getElementById("quick-task-input")?.focus();
    }, 0);
  };
  const inputValue = watch("input");

  const quickTags = useQuickTags(quickFormMode, inputValue);

  const submit = handleSubmit(async ({ input }) => {
    if (quickFormMode === "search") {
      return;
    }

    const marker = getExtraMarker(inputValue);

    const taskName = clearMarkers(input);
    const dueBy = getMarkerDueBy(marker);
    const priority = getMarkerPriority(marker);

    const tags = getTags(quickTags, currentTag, marker);

    await createTask({
      name: taskName,
      tags,
      priority,
      description: "",
      dueBy,
    });
    reset();
    focusInput();
  });

  const toggleQuickFormMode = () => {
    setQuickFormMode(quickFormMode === "add" ? "search" : "add");
    reset();
    focusInput();
  };

  useEffect(() => {
    if (quickFormMode === "search") {
      setSearch(inputValue);
    } else {
      setSearch("");
    }
  }, [inputValue, quickFormMode]);

  useOnKeyWhenNoFocus("f", () => {
    setQuickFormMode("search");
    focusInput();
  });

  useOnKey("Escape", () => {
    setQuickFormMode("add");
    reset();
  });

  useEffect(() => {
    if (inputValue === "ff" && quickFormMode === "add") {
      setQuickFormMode("search");
      reset();
    }
  }, [quickFormMode, inputValue]);

  const extended = (() => {
    if (quickTags.length) {
      return true;
    }
    if (getExtraMarker(inputValue)) {
      return true;
    }
    if (isMobile() && inputValue.length > 20) {
      return true;
    }
    return false;
  })();

  return (
    <>
      <Box
        direction="row"
        width="100%"
        align="center"
        margin={{ bottom: "small" }}
      >
        <Button
          icon={<PowerCycle color="white" />}
          onClick={toggleQuickFormMode}
        />
        <Frm onSubmit={submit}>
          <Input
            {...register("input")}
            autoComplete="off"
            quickFormMode={quickFormMode}
            id="quick-task-input"
            rows={extended ? 4 : 1}
            extended={extended}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                submit();
              }
            }}
          />
          <QuickTags>
            <TagBar tagIds={quickTags} />
          </QuickTags>
          <InfoIcon>{getMarkerIcon(inputValue)}</InfoIcon>
        </Frm>
        {quickFormMode === "add" ? (
          <AddTaskButton
            tagName={currentTag}
            onClick={inputValue.length ? submit : undefined}
          />
        ) : (
          <Button icon={<Close />} onClick={() => reset()} />
        )}
      </Box>
    </>
  );
};
