import { Link } from "react-router-dom";
import React from "react";

export function Home() {
  return (
    <h1 style={{ textAlign: "center" }}>
      <Link to={"/ota"}>Ota</Link>
      <br />
      <br />
      <br />
      <Link to={"/ken"}>Ken</Link>
      <br />
      <br />
      <br />
      <Link to={"/jikan"}>Jikan</Link>
    </h1>
  );
}
