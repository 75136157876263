import React from "react";
import styled, { keyframes } from "styled-components";

// Define a keyframes animation for rotation
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Switcher = styled.div`
  display: none;

  @media (min-width: 600px) {
    display: block;
    position: fixed;
    bottom: 15px;
    right: 15px;
    font-size: 3rem;

    // Add the rotation animation with a duration of 10 seconds
    animation: ${rotate} 10s linear infinite;

    a {
      all: unset;
      cursor: pointer;
    }
  }
`;

export const AppSwitcher = () => {
  const location = window.location.pathname;

  const isKen = location.startsWith("/ken");

  const href = isKen ? "/ota" : "/ken";

  return (
    <Switcher>
      <a href={href}>*</a>
    </Switcher>
  );
};
