import React, { FC } from "react";
import styled from "styled-components";
import {
  clearDebugDate,
  getDebugDate,
  setDebugDate,
} from "../utils/localStorage";
import { dateToString } from "../utils/date";
import { Layout } from "./Layout";

const DateDebuggerBox = styled.div`
  width: 100%;
  background: #fe3838;
  color: white;
  margin-bottom: 20px;
  padding: 10px;
  --main-color: white;
`;

export const DateDebugger: FC = () => {
  const debugDate = getDebugDate();

  if (!debugDate) {
    return null;
  }

  const setPrevDay = () => {
    const date = new Date(debugDate);
    date.setDate(date.getDate() - 1);
    setDebugDate(date);
  };

  const setNextDay = () => {
    const date = new Date(debugDate);
    date.setDate(date.getDate() + 1);
    setDebugDate(date);
  };

  return (
    <>
      <DateDebuggerBox>
        <p>Symulowana data: {dateToString(debugDate)}</p>
        <Layout direction="column" gap={20}>
          <Layout direction="row" gap={10}>
            <button type="button" onClick={setPrevDay}>
              Poprzedni dzień
            </button>

            <button type="button" onClick={setNextDay}>
              Następny dzień
            </button>
            <button type="button" onClick={clearDebugDate}>
              Wyłącz
            </button>
          </Layout>
        </Layout>
      </DateDebuggerBox>
    </>
  );
};
