import {
  Box,
  Button,
  Form,
  FormField,
  RadioButton,
  Text,
  TextArea,
} from "grommet";
import React, { FC, useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { TagPicker } from "./TagPicker";
import { usePersistedState } from "../../common/util/react";
import { Add } from "grommet-icons";
import { QuestCreateDto, QuestDto, TaskPriority } from "../dtos";
import { endOfTheDay } from "../../common/util/date";

interface PriorityOption {
  label: React.ReactNode;
  value: TaskPriority;
}

const priorityOptions: PriorityOption[] = [
  { label: "Ważne", value: "important" },
  { label: "Ważne dla tagu", value: "tag-important" },
  { label: "Zrobić", value: "planned" },
  { label: "Kiedyś", value: "to-be-planned" },
];

interface TaskFormProps {
  task?: QuestDto;
  backButton?: React.ReactNode;
  children?: (
    form: UseFormReturn<QuestCreateDto, any, undefined>
  ) => React.ReactNode;

  defaultTag?: string;

  onSubmit(task: QuestCreateDto): void;
}

export const TaskForm: FC<TaskFormProps> = (props) => {
  const { task, defaultTag, onSubmit } = props;

  const [lastUsedPriority, setLastUsedPriority] = usePersistedState<
    TaskPriority | undefined
  >("lastUsedPriority", "to-be-planned");

  const form = useForm<QuestCreateDto>({
    defaultValues: task || {
      name: "",
      description: "",
      tags: defaultTag ? [defaultTag] : [],
      priority: lastUsedPriority,
    },
  });
  const { register, handleSubmit, watch, setValue } = form;

  const selectedPriority = watch("priority");
  const tags = watch("tags");
  const dueBy = watch("dueBy");

  const [showDescription, setShowDescription] = useState(
    Boolean(task?.description)
  );

  useEffect(() => {
    if (
      dueBy &&
      (selectedPriority === "to-be-planned" || selectedPriority === "inbox")
    ) {
      setValue("priority", "planned");
    }
  }, [selectedPriority, dueBy]);

  const isEditForm = Boolean(task);

  const renderPriorityOption = (option: PriorityOption) => (
    <RadioButton
      value={option.value}
      key={option.value}
      label={option.label}
      checked={selectedPriority === option.value}
      {...register("priority", {
        onChange: (e) => setLastUsedPriority(e.target.value as any),
      })}
    />
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {props.children?.(form)}
      <Box gap="medium">
        <FormField
          {...register("name", { required: true })}
          autoFocus={!isEditForm}
          style={{
            fontSize: "20px",
          }}
        />

        <Box
          gap="medium"
          direction="row"
          pad="small"
          align="start"
          justify="between"
          width={{ max: "medium" }}
          border={{
            color: selectedPriority === "inbox" ? "brand" : "transparent",
            size: "medium",
            style: "dashed",
          }}
        >
          {renderPriorityOption(priorityOptions[0])}
          <Box direction="column" gap="medium">
            {renderPriorityOption(priorityOptions[2])}
            {renderPriorityOption(priorityOptions[1])}
          </Box>
          {renderPriorityOption(priorityOptions[3])}
        </Box>

        <TagPicker
          tags={tags}
          onChange={(updatedTags) => {
            setValue("tags", updatedTags);
          }}
        />

        <Box gap="small" direction="column">
          <Text>Opis</Text>

          {showDescription ? (
            <TextArea
              {...register("description")}
              rows={5}
              style={{
                fontSize: "18px",
              }}
            />
          ) : (
            <Button icon={<Add />} onClick={() => setShowDescription(true)} />
          )}
        </Box>

        <Box
          align="center"
          justify="around"
          direction="row"
          gap="medium"
          margin={{ top: "large" }}
        >
          {props.backButton}
          <Button type="submit" label="Zapisz" />
        </Box>
      </Box>
    </Form>
  );
};
