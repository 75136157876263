import { QuestDto } from "../dtos";

function copyToClipboard(text: string) {
  const el = document.createElement("textarea");
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}

export const askAi = async (quest: QuestDto) => {
  const prompt = `
Pomóż w rozwiązaniu zadania:

${quest.name}
${quest.description}
Tagi: ${quest.tags.join(", ")}    

Zasugeruj rozwiązania.
Pomyśl o nieoczywistych rozwiązaniach.
Max. 3 krótkie punkty.
    
Rozmowę nazwij: Guts - task - ${quest.name};    
    `;

  copyToClipboard(prompt);

  window.open("https://chatgpt.com/", "_blank");
};
