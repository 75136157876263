import { useGlobal } from "../global";
import { CommandQueueItemStatus, QuestCreateDto } from "../dtos";

const getNewId = () => Math.random().toString(36).substring(7);

export const useCommandQueue = () => {
  const { queue, setQueue } = useGlobal();

  const addToQueue = (task: QuestCreateDto) => {
    const queueId = getNewId();
    setQueue((q) => [
      ...q,
      { task, queueId, queuedTime: Date.now(), status: "pending" },
    ]);
    return queueId;
  };

  const removeFromQueue = (queueId: string) => {
    setQueue((q) => q.filter((item) => item.queueId !== queueId));
  };

  const changeStatus = (queueId: string, status: CommandQueueItemStatus) => {
    setQueue((q) =>
      q.map((item) => {
        if (item.queueId === queueId) {
          return { ...item, status };
        }
        return item;
      })
    );
  };

  return {
    queue,
    addToQueue,
    changeStatus,
    removeFromQueue,
  };
};
