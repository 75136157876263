import { JikanEvent } from "./JikanCalendar";
import { useEffect, useState } from "react";
import { CalendarEvent } from "./type";
import { loadEvents } from "./apiGoogle";

const eventToJikanEvent = (event: CalendarEvent): JikanEvent => ({
  id: event.id,
  date: new Date(event.start.dateTime || event.start.date || new Date()),
  title: event.summary,
  type: "event",
});

export const useJikanCalendar = (): JikanEvent[] => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);

  // useEffect(() => {
  //   authenticateAndLoadEvents().then((events) => {
  //     setEvents(events);
  //   });
  // }, []);

  useEffect(() => {
    loadEvents().then((ev) => {
      console.log(ev);
      setEvents(ev as CalendarEvent[]);
    });
  }, []);

  return events.map(eventToJikanEvent);
};
