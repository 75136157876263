import { useMutation, useQuery } from "@tanstack/react-query";
import { useGlobal } from "../global";
import {
  createTag,
  createTask,
  deleteTask,
  getTags,
  getTaskById,
  getTasksUndoneAndDoneThisWeek,
  markTaskAsDone,
  markTaskAsUndone,
  toggleTask,
  updateTag,
  updateTask,
} from "./api";
import { message } from "./message";
import { QuestCreateDto, QuestDto, TagCreateDto, TagDto } from "../dtos";
import { queryClient } from "../../ota/utils/queries";
import { useCommandQueue } from "./command-queue";

export const useTasks_ = () => {
  const { data = [], isLoading } = useQuery(
    ["tasks"],
    getTasksUndoneAndDoneThisWeek
  );
  return [data, isLoading] as const;
};

export const useTasks = () => {
  const key = "TAASKS";

  let storedTasks: QuestDto[] = [];
  try {
    storedTasks = JSON.parse(localStorage.getItem(key) as any) || [];
  } catch (e) {}

  const { data = [], isLoading } = useQuery(["tasks"], async () => {
    const result = await getTasksUndoneAndDoneThisWeek();
    localStorage.setItem(key, JSON.stringify(result));
    return result;
  });

  return [data.length ? data : storedTasks, isLoading] as const;
};

export const useTask = (id: string) => {
  const { data, isLoading } = useQuery(["task", id], () => getTaskById(id));
  return [data, isLoading] as const;
};

export const useToggleDone = () => {
  const { mutate, isLoading } = useMutation(
    ["toggleTask"],
    ({ id, markAsDone }: { id: string; markAsDone: boolean }) => {
      if (markAsDone) {
        return markTaskAsDone(id);
      } else {
        return markTaskAsUndone(id);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tasks"]);
      },
    }
  );
  return [mutate, isLoading] as const;
};

export const useDeleteTask = () => {
  const { mutate, isLoading } = useMutation(
    ["toggleTask"],
    async (_id: string) => {
      await message(`Abandoning...`, 500, "black");
      deleteTask(_id);
      return _id;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tasks"]);
      },
    }
  );
  return [mutate, isLoading] as const;
};

export const useCreateTaskWithQueue = () => {
  const { tags } = useGlobal();
  const { addToQueue, removeFromQueue, changeStatus } = useCommandQueue();
  const existingTagNames = tags.map((tag) => tag.name);

  const { mutate, isLoading } = useMutation(
    ["createTask"],
    async ({ task, queueId }: { task: QuestCreateDto; queueId: string }) => {
      try {
        const created = await createTask(task);
        removeFromQueue(queueId);
        return created;
      } catch (e) {
        changeStatus(queueId, "error");
        throw e;
      }
    },
    {
      onSuccess: (_, { task }) => {
        message(`New Quest: ${task.name}`, 800, "black");
        queryClient.invalidateQueries(["tasks"]);
        if (task.tags.some((tag) => !existingTagNames.includes(tag))) {
          queryClient.invalidateQueries(["tags"]);
        }
      },
    }
  );

  const runMutate = (task: QuestCreateDto) => {
    const queueId = addToQueue(task);

    mutate({ task, queueId });
  };

  return [runMutate, isLoading] as const;
};

export const useUpdateTask = () => {
  const { tags } = useGlobal();
  const existingTagNames = tags.map((tag) => tag.name);

  const { mutate, isLoading } = useMutation(
    ["updateTask"],
    ({ id, task }: { id: string; task: QuestCreateDto }) => {
      return updateTask(id, task);
    },
    {
      onSuccess: (_result, { task }) => {
        queryClient.invalidateQueries(["tasks"]);
        if (task.tags.some((tag) => !existingTagNames.includes(tag))) {
          queryClient.invalidateQueries(["tags"]);
        }
      },
    }
  );
  return [mutate, isLoading] as const;
};

export const useTags = () => {
  const key = "TAGS";
  let storedTags: TagDto[] = [];
  try {
    storedTags = JSON.parse(localStorage.getItem(key) as any) || [];
  } catch (e) {}

  const { data = [], isLoading } = useQuery(["tags"], async () => {
    const result = await getTags();
    localStorage.setItem(key, JSON.stringify(result));
    return result;
  });
  return [data.length ? data : storedTags, isLoading] as const;
};

export const useCreateTag = () => {
  const { mutate, isLoading } = useMutation(
    ["createTag"],
    (tag: TagCreateDto) => {
      return createTag(tag);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tags"]);
      },
    }
  );
  return [mutate, isLoading] as const;
};

export const useUpdateTag = () => {
  const { mutate, isLoading } = useMutation(
    ["updateTag"],
    (tag: TagDto) => {
      return updateTag(tag);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tags"]);
      },
    }
  );
  return [mutate, isLoading] as const;
};
