import { Layout } from "./Layout";
import { FC } from "react";
import { Frequency, frequencyText } from "../utils/text";
import { Field, useFormikContext } from "formik";
import { IPlanWithoutId } from "./PlanForm";
import styled from "styled-components";

const Line = styled.p`
  margin: 0;
`;

const LineWithGap = styled(Line)`
  display: flex;
  gap: 20px;
`;

interface FrequencySelectProps {
  field: keyof Pick<
    IPlanWithoutId,
    "frequencyNormal" | "frequencyBusy" | "frequencyOff"
  >;
}

const promptNumber = (message: string) => {
  const x = prompt(message);
  return x ? parseInt(x) : undefined;
};

export const FrequencySelectV2: FC<FrequencySelectProps> = (props) => {
  const { values, setFieldValue } = useFormikContext<IPlanWithoutId>();

  const frequencyValue = values[props.field];
  const setValue = (frequency: string) => {
    setFieldValue(props.field, frequency);
  };

  return (
    <label>
      <Layout direction="column" gap={5}>
        <Line>
          <strong>{props.field}</strong>
        </Line>
        <Line>{frequencyText(frequencyValue)}</Line>
        <Field name={props.field} type="text" />
        <LineWithGap>
          <button type="button" onClick={() => setValue(Frequency.Zero)}>
            Zero
          </button>
          <button type="button" onClick={() => setValue(Frequency.Daily)}>
            Daily
          </button>
          <button
            type="button"
            onClick={() => {
              const x = promptNumber("X times a day");
              if (x !== undefined) {
                setValue(`${x}/1`);
              }
            }}
          >
            X times a day
          </button>
          <button
            type="button"
            onClick={() => {
              const x = promptNumber("Every X days");
              if (x !== undefined) {
                setValue(`1/${x}`);
              }
            }}
          >
            Every X days
          </button>
        </LineWithGap>
      </Layout>
    </label>
  );
};
