import { Box, Button, Calendar, Page, Text } from "grommet";
import { FC, useState } from "react";
import { useLocation, useParams } from "react-router";
import { MyLink } from "../components/MyLink";
import { TaskForm } from "../components/TaskForm";
import { useDeleteTask, useTask, useUpdateTask } from "../util/queries";
import { tagParam } from "../util/tags";
import { useGlobal } from "../global";
import { Clock, Close, Deploy, Icon, Launch, Trash } from "grommet-icons";
import { TaskPriority } from "../dtos";

import { useNavigateKen } from "../navigation";
import { message } from "../util/message";
import {
  endOfTheDay,
  isDueThisDay,
  isDueNextDay,
  shortDate,
} from "../../common/util/date";
import { Modal } from "../components/Modal";
import { DateSelect } from "../components/DateSelect";
import { isToday } from "date-fns";
import { dueColor } from "../util/task";
import { Link, useNavigate } from "react-router-dom";

const priorityActions: {
  priority: TaskPriority;
  icon: Icon;
  rotate?: boolean;
}[] = [
  {
    priority: "important",
    icon: Deploy,
  },
  {
    priority: "planned",
    icon: Launch,
  },
  {
    priority: "to-be-planned",
    icon: Launch,
    rotate: true,
  },
];

export const TaskEditPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const ota = new URLSearchParams(location.search).get("ota");
  const navigate = useNavigate();
  const { currentTag } = useGlobal();

  const backUrl = ota ? "/ota" : "/ken" + tagParam(currentTag);

  const [task] = useTask(id || "");
  const [updateTask] = useUpdateTask();
  const [deleteTask] = useDeleteTask();

  const onClickDelete = async () => {
    if (window.confirm("Usunąć zadanie?")) {
      await message(`Abandoning...`, 500, "black");
      await deleteTask(id!);
      navigate(backUrl);
    }
  };

  const onPriorityChange = async (priority: TaskPriority) => {
    if (!id || !task) {
      throw new Error("No task");
    }
    if (task.priority === priority) {
      return;
    }
    await updateTask({ id, task: { ...task, priority } });
    navigate(backUrl);
  };

  const getPriorityProps = (priority: TaskPriority) => {
    if (priority === task?.priority) {
      return {};
    }

    return {
      onClick: () => onPriorityChange(priority),
      color: "brand",
    };
  };

  if (!id || !task) {
    return null;
  }

  return (
    <Page gap="large" pad="medium">
      <TaskForm
        task={task}
        onSubmit={async (task) => {
          await updateTask({ id, task });
          navigate(backUrl);
        }}
        backButton={<Link to={backUrl}>Wróć</Link>}
      >
        {(form) => {
          const dueBy = form.watch("dueBy");

          return (
            <Box direction="row" gap="medium" justify="center">
              {priorityActions.map((action) => {
                const IconComponent = action.icon;
                const isCurrent = action.priority === task.priority;
                return (
                  <Box>
                    <Button
                      icon={
                        <IconComponent
                          color={isCurrent ? undefined : "brand"}
                          size="40px"
                          style={{
                            transform: action.rotate
                              ? "rotate(180deg)"
                              : undefined,
                          }}
                        />
                      }
                      disabled={isCurrent}
                      onClick={() => onPriorityChange(action.priority)}
                    />
                  </Box>
                );
              })}

              <DateSelect
                value={dueBy}
                setValue={(value) => form.setValue("dueBy", value)}
              >
                {({ open }) => (
                  <Box align="center" gap="none">
                    <Button
                      icon={
                        <Clock
                          color={dueBy ? dueColor(dueBy) : "brand"}
                          size="40px"
                        />
                      }
                      onClick={open}
                    />
                    {dueBy && <Text size="xsmall">{shortDate(dueBy)}</Text>}
                  </Box>
                )}
              </DateSelect>

              <Box>
                <Button
                  icon={<Trash color="brand" size="40px" />}
                  onClick={onClickDelete}
                />
              </Box>
            </Box>
          );
        }}
      </TaskForm>
    </Page>
  );
};
