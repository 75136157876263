import { Box } from "grommet";
import { TaskGroup } from "../../../ken/components/TaskGroup";
import React, { FC } from "react";
import { taskUtil } from "../../../ken/util/task";
import { useGlobal } from "../../../ken/global";
import { stringToDate } from "../../utils/date";

interface DayKenProps {
  day: string;
}

export const KenZone: FC<DayKenProps> = ({ day }) => {
  const dayDate = stringToDate(day);

  const plannedQuests = taskUtil(useGlobal().tasks).isPlannedOn(dayDate).tasks;

  if (!plannedQuests.length) {
    return null;
  }

  return (
    <Box margin={{ vertical: "medium" }} className="oka-quests">
      <TaskGroup
        tasks={plannedQuests}
        groupId="ota"
        order="newest"
        isOta
        time={dayDate.getTime()}
      />
    </Box>
  );
};
