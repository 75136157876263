import React, { FC } from "react";
import styled from "styled-components";
import { oathImage, tabletOrBigger } from "../../style";

const StyledWrapper = styled.div`
  background-image: ${oathImage};
  background-size: 20%;

  padding: 20px;
  ${tabletOrBigger} {
    padding: 40px;
  }

  input {
    font-size: 1.2rem;

    padding: 10px 5px;
    ${tabletOrBigger} {
      padding: 20px 10px;
    }
  }

  td {
    padding: 5px;
    ${tabletOrBigger} {
      padding: 10px;
    }
  }
`;

const InnerWrapper = styled.div`
  background-color: white;
  padding: 20px;
`;

interface OathWrapperProps {
  oath?: boolean;
  children: React.ReactNode;
}
export const OathWrapper: FC<OathWrapperProps> = (props) => {
  const { oath = true, children } = props;
  if (oath) {
    return (
      <StyledWrapper>
        <InnerWrapper>{children}</InnerWrapper>
      </StyledWrapper>
    );
  }
  return <>{children}</>;
};
