import { FC } from "react";
import styled from "styled-components";

import { getKcalColor, getKcalRate, KcalRate } from "../utils/kcal";

function getMessage(rate: KcalRate, dayOfMonth: number) {
  const getGroup = () => {
    switch (rate) {
      case "fasting":
        return [
          "You're fasting with the discipline of a Kung-Fu master!",
          "Keep strong like a monk during your fasting period!",
          "Your fasting discipline is as impressive as a Shaolin monk's perseverance!",
          "Stay committed to your fasting, channeling the focus of a martial arts expert!",
          "Fasting mode: Your dedication rivals that of a Kung-Fu warrior!",
          "Excellent job sticking to your fasting schedule with monk-like discipline!",
          "Your fasting effort is commendable, like a master in training!",
          "Keep it up, fasting with the perseverance of a Kung-Fu grandmaster!",
          "You're mastering the art of fasting like a true martial artist!",
          "Fasting success: You're on the right track with the focus of a monk!",
          "Stay focused, your fasting journey is strong like a warrior's resolve!",
          "You're doing well, keep fasting like a disciplined monk!",
        ];
      case "loss":
        return [
          "You're in a caloric deficit. Keep it up!",
          "Stay strong on your caloric deficit journey!",
          "Keep burning those calories, you're doing great!",
          "Your caloric deficit is working well!",
          "Deficit mode: You're on the right track!",
          "Keep up the good work, you're in a deficit!",
          "Caloric deficit achieved, keep going!",
          "Deficit mode success! Stay focused!",
          "You're managing your deficit excellently!",
          "Keep it up, the deficit is paying off!",
          "Your deficit strategy is working!",
          "You're on a good path with this deficit!",
          "Keep pushing, the deficit is effective!",
          "You're in a good caloric deficit, continue!",
          "Deficit journey is strong, keep it up!",
          "Your deficit game is strong!",
          "Stay in that deficit zone!",
          "Great job with your caloric deficit!",
          "Deficit mode: You're doing great!",
          "Keep on with that deficit, you're winning!",
          "You're successfully in a deficit!",
          "Deficit achieved, stay the course!",
          "You're excelling at maintaining a deficit!",
          "Deficit mode: continue the good work!",
          "Your deficit is well-managed!",
          "Deficit progress is evident, keep it up!",
          "You're mastering the deficit strategy!",
          "Stay strong in your deficit!",
          "You're on track with your deficit goals!",
          "Keep burning those calories!",
          "Excellent deficit management, keep going!",
        ];

      case "maintain":
        return [
          "You're maintaining your weight. Good job!",
          "Nice work on maintaining your weight!",
          "Keep steady, you're maintaining well!",
          "Maintenance mode: You're doing well!",
          "Consistent maintenance, keep it up!",
          "Good job maintaining your current weight!",
          "You're doing great at keeping your weight stable!",
          "Maintenance is going smoothly, great job!",
          "You're managing to maintain perfectly!",
          "Keep up the good work maintaining your weight!",
          "Your maintenance strategy is effective!",
          "Stay steady, you're maintaining well!",
          "Great job keeping your weight consistent!",
          "Your weight maintenance is impressive!",
          "Keep it up, you're maintaining well!",
          "You're on track with maintaining your weight!",
          "Maintain mode: You're doing excellent!",
          "Consistency is key, and you're achieving it!",
          "Your weight maintenance is on point!",
          "You're excelling in maintaining your weight!",
          "Keep maintaining, you're doing great!",
          "Maintenance success, keep it up!",
          "You're mastering weight maintenance!",
          "Maintenance mode: You're handling it well!",
          "Your maintenance efforts are paying off!",
          "Excellent job maintaining your weight!",
          "You're maintaining your weight perfectly!",
          "Stay strong, your maintenance is solid!",
          "Good job keeping your weight steady!",
          "You're successfully maintaining your weight!",
          "Keep up the great work maintaining!",
        ];
      case "maintain+":
        return [
          "You're in a slight caloric surplus. Watch out!",
          "Slight surplus, be mindful!",
          "You're just above maintenance, be careful!",
          "Slight surplus detected, watch your intake!",
          "A little over maintenance, monitor closely!",
          "Keep an eye on that slight surplus!",
          "You're in a small surplus, watch out!",
          "Slight surplus, keep monitoring!",
          "Your surplus is minimal, stay alert!",
          "Watch your intake, slight surplus detected!",
          "Be cautious of your slight surplus!",
          "Monitor your intake, slight surplus observed!",
          "A small surplus, be mindful!",
          "Stay vigilant, slight surplus detected!",
          "You're just a bit over, watch it!",
          "Slight surplus, adjust as needed!",
          "Mind the slight surplus, be careful!",
          "You're above maintenance, stay aware!",
          "Keep a check on that slight surplus!",
          "Slight surplus noticed, monitor closely!",
          "Be cautious, you're slightly over!",
          "Watch your diet, slight surplus here!",
          "Slight caloric surplus, stay focused!",
          "Stay alert, you're slightly over!",
          "Monitor your diet, slight surplus detected!",
          "You're in a slight surplus, be cautious!",
          "Be mindful, slight surplus present!",
          "Slightly above maintenance, adjust intake!",
          "Slight surplus, keep an eye on it!",
          "Stay aware, slight surplus observed!",
          "Adjust accordingly, slight surplus detected!",
        ];
      case "gain":
      case "gain+":
      case "gain++":
      case "gain+++":
      case "gain++++":
        return [
          "You're gaining weight. Watch out!",
          "Weight gain detected, be cautious!",
          "You're in a gain mode, monitor closely!",
          "Gaining weight, adjust your intake!",
          "Be mindful of your weight gain!",
          "Watch out, weight gain is happening!",
          "You're in a weight gain phase, be careful!",
          "Weight gain mode, adjust as needed!",
          "You're gaining weight, stay alert!",
          "Monitor your diet, weight gain observed!",
          "Weight gain, watch your intake closely!",
          "Be cautious, weight gain detected!",
          "Adjust your intake, weight gain noticed!",
          "Stay vigilant, weight gain present!",
          "Weight gain phase, be mindful!",
          "Be aware of your weight gain!",
          "Watch your intake, weight gain phase!",
          "You're in gain mode, adjust intake!",
          "Stay focused, weight gain observed!",
          "Mind your diet, weight gain present!",
          "Adjust accordingly, weight gain detected!",
          "Stay cautious, weight gain phase!",
          "Be careful, weight gain noticed!",
          "Monitor closely, weight gain present!",
          "You're gaining, adjust your intake!",
          "Stay alert, weight gain mode!",
          "Weight gain detected, adjust intake!",
          "Monitor your diet, gain phase detected!",
          "Stay mindful, weight gain happening!",
          "Adjust intake, weight gain present!",
          "Weight gain mode, monitor intake!",
        ];
    }
  };

  // Select message based on the day of the month
  const rateMessages = getGroup();
  const messageIndex = (dayOfMonth - 1) % rateMessages.length;
  return rateMessages[messageIndex];
}

const KcalInputBox = styled.label`
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    text-align: center;
    font-weight: bold;
    margin: 10px 0;
  }
`;

export const KcalInput: FC<{
  kcal: number | undefined;
  setKcal: (kcal: number) => void;
}> = ({ kcal, setKcal }) => {
  const kcalRate = getKcalRate(kcal);
  const message = kcalRate && getMessage(kcalRate, new Date().getDate());

  return (
    <KcalInputBox>
      <span>Kcal:</span>
      <input
        style={{ backgroundColor: getKcalColor(kcalRate) }}
        type="number"
        value={kcal}
        onChange={(e) => setKcal(+e.target.value)}
      />
      {message ? <p>{message}</p> : <p style={{ visibility: "hidden" }}>-</p>}
    </KcalInputBox>
  );
};

export const KcalInfo: FC<{ kcal: number }> = ({ kcal }) => {
  const kcalRate = getKcalRate(kcal);
  const kcalColor = getKcalColor(kcalRate);

  return (
    <div
      style={{
        color: kcalColor,
        padding: "10px",
        border: `1px solid ${kcalColor}`,
      }}
    >
      Kcal: {kcal}
    </div>
  );
};
