import { FC } from "react";
import { useCreateTag } from "../util/queries";
import { useForm } from "react-hook-form";
import { Box, Button, Form, FormField } from "grommet";
import { Add } from "grommet-icons";
import { TagCreateDto } from "../dtos";

interface TagFormProps {
  onAdded: (newTagName: string) => void;
}

export const TagForm: FC<TagFormProps> = (props) => {
  const { onAdded } = props;
  const [createTag] = useCreateTag();
  const { reset, register, handleSubmit } = useForm<TagCreateDto>();

  const submitHandler = handleSubmit(async (data) => {
    await createTag(data);
    onAdded(data.name);
    reset();
  });

  return (
    <Form
      onSubmit={(e) => {
        e.stopPropagation();
        return submitHandler();
      }}
    >
      <Box direction="row" gap="small" justify="center">
        <FormField {...register("name")} required placeholder="Nowy tag" />
        <Button type="submit" icon={<Add />} />
      </Box>
    </Form>
  );
};
