import { FC } from "react";
import { frequencyText } from "../utils/text";
import { GenerationMode, IPlan } from "../utils/types";
import { OathWrapper } from "./OathWrapper";

import { LinkOta } from "../navigation";
import styled, { css } from "styled-components";
import { choreDisplayedName, choreTags } from "../utils/chore";

const Table = styled.div<{ mode: GenerationMode | null }>`
  border: 1px solid darkgrey;
  padding: 10px;
  font-size: 1.2rem;
  display: grid;
  gap: 10px;
  width: 90%;

  ${(props) =>
    props.mode &&
    css`
      [data-mode] {
        display: none;
      }
      [data-mode="${props.mode}"] {
        display: block;
      }
    `}
`;

const Row = styled.div<{ heading?: boolean; mode: GenerationMode | null }>`
  display: grid;

  grid-template-columns: 1fr repeat(${(props) => (props.mode ? 1 : 3)}, 1fr) 50px;

  grid-gap: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }

  ${({ heading }) =>
    heading &&
    `
        font-weight: bold;
    `}
`;

const Cell = styled.div<{ "data-mode"?: GenerationMode }>`
  padding: 8px 4px;
  a {
    text-decoration: none;
  }

  ${(props) => {
    switch (props["data-mode"]) {
      case "normal":
        return css``;
      case "busy":
        return css`
          font-size: 0.8em;
        `;
      case "off":
        return css`
          font-size: 0.8em;
          color: #999;
        `;
    }
  }}
`;

const frequencyByMode = (plan: IPlan, mode: GenerationMode) => {
  switch (mode) {
    case "normal":
      return plan.frequencyNormal;
    case "busy":
      return plan.frequencyBusy;
    case "off":
      return plan.frequencyOff;
  }
};

const frequencyOrder = (frequency: string) => {
  const [a, b] = frequency.split("/");
  return (parseInt(a) / parseInt(b)).toString();
};

interface PlansTableProps {
  plans: IPlan[];
  mode: GenerationMode | null;
}

export const PlansTable: FC<PlansTableProps> = (props) => {
  const { plans, mode } = props;

  if (!plans.length) {
    return <p className="center">...</p>;
  }

  const hasOaths = plans.some((plan) => plan.isOath);

  const plansSorted = () => {
    const sortMode = mode || "normal";

    return [...plans].sort((a, b) => {
      const aFrequency = frequencyByMode(a, sortMode);
      const bFrequency = frequencyByMode(b, sortMode);

      return frequencyOrder(bFrequency).localeCompare(
        frequencyOrder(aFrequency)
      );
    });
  };

  return (
    <OathWrapper oath={hasOaths}>
      <Table mode={mode}>
        <Row heading mode={mode}>
          <Cell />
          {hasOaths ? (
            <>
              <Cell data-mode="normal" />
              <Cell data-mode="busy" />
              <Cell data-mode="off" />
            </>
          ) : (
            <>
              <Cell data-mode="normal">Normal</Cell>
              <Cell data-mode="busy">Busy</Cell>
              <Cell data-mode="off">Off</Cell>
            </>
          )}
          <Cell />
        </Row>
        {plansSorted().map((plan) => (
          <PlanRow key={plan._id} plan={plan} mode={mode} />
        ))}
      </Table>
    </OathWrapper>
  );
};

interface PlanRowProps {
  plan: IPlan;
  mode: GenerationMode | null;
}

const PlanRow: FC<PlanRowProps> = (props) => {
  const { plan, mode } = props;

  const activeStyle = {
    textDecoration: plan.active ? undefined : "line-through",
  };

  const editLink = plan.isOath
    ? `/oath/edit/${plan._id}`
    : `/plan/edit/${plan._id}`;

  return (
    <Row mode={mode}>
      <Cell style={activeStyle}>
        {choreDisplayedName(plan.choreName)}
        {choreTags(plan.choreName).map((tag) => (
          <span key={tag} className="tag" style={{ color: "orange" }}>
            {" "}
            #{tag}
          </span>
        ))}
      </Cell>
      {plan.isOath ? (
        <>
          <Cell data-mode="normal" />
          <Cell data-mode="busy" />
          <Cell data-mode="off" />
        </>
      ) : (
        <>
          <Cell data-mode="normal" style={activeStyle}>
            {frequencyText(plan.frequencyNormal)}
          </Cell>
          <Cell data-mode="busy" style={activeStyle}>
            {frequencyText(plan.frequencyBusy)}
          </Cell>
          <Cell data-mode="off" style={activeStyle}>
            {frequencyText(plan.frequencyOff)}
          </Cell>
        </>
      )}

      <Cell>
        <LinkOta to={editLink}>✐</LinkOta>
      </Cell>
    </Row>
  );
};
