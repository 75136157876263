import React, { FC } from "react";
import styled, { css } from "styled-components";
import {
  getDayStatus,
  nextDayString,
  nextMonthString,
  now,
  previousDayString,
  previousMonthString,
} from "../../utils/date";
import { DayStatus } from "../../utils/types";
import { Layout } from "../Layout";
import { tabletOrBigger } from "../../../style";

import { LinkOta } from "../../navigation";
import { WriteLink } from "../WriteLink";

const StyledLayout = styled(Layout)`
  align-items: center;

  justify-content: space-around;
  ${tabletOrBigger} {
    justify-content: flex-start;
  }

  a {
    display: block;
    text-decoration: none;
    font-size: 18px;
    ${tabletOrBigger} {
      font-size: 20px;
    }
  }
`;

const DayH1 = styled.h1<{ dayStatus: DayStatus }>`
  font-size: 22px;
  ${tabletOrBigger} {
    font-size: 30px;
  }

  ${(props) =>
    props.dayStatus === "past" &&
    css`
      text-decoration: line-through;
    `}
`;

interface DayNavigationProps {
  day: string;
  isWrite?: boolean;
  written?: string;
}

export const DayNavigation: FC<DayNavigationProps> = (props) => {
  const { day, isWrite, written } = props;

  const responsible = "all";
  const dayStatus = getDayStatus(day, now());

  const weekDay = new Date(day).toLocaleDateString("pl-PL", {
    weekday: "short",
  });

  const dayString = dayStatus === "current" ? "Dzisiaj" : `${day} (${weekDay})`;

  const [suffix, antiSuffix] = !isWrite ? ["", "write"] : ["write", ""];

  const prevDayUrl = `/${responsible}/${previousDayString(day)}/${suffix}`;
  const nextDayUrl = `/${responsible}/${nextDayString(day)}/${suffix}`;

  const showNext = !(isWrite && dayStatus === "current");

  return (
    <StyledLayout direction="row" gap={20}>
      <LinkOta to={prevDayUrl}>{"<<<"}</LinkOta>
      <Layout center gap={10}>
        <DayH1 dayStatus={dayStatus}>{dayString}</DayH1>
        <WriteLink back={isWrite} written={written} day={day} />
      </Layout>
      <LinkOta
        to={nextDayUrl}
        style={{ visibility: showNext ? "visible" : "hidden" }}
      >
        {">>>"}
      </LinkOta>
    </StyledLayout>
  );
};

interface MonthNavigationProps {
  month: string;
}

export const MonthNavigation: FC<MonthNavigationProps> = (props) => {
  const { month } = props;

  const nextMonthUrl = `/calendar/${nextMonthString(month)}`;
  const prevMonthUrl = `/calendar/${previousMonthString(month)}`;

  return (
    <StyledLayout direction="row" gap={20}>
      <LinkOta to={prevMonthUrl}>{"<<<"}</LinkOta>
      <Layout center gap={10}>
        <DayH1 dayStatus={"current"}>{month}</DayH1>
      </Layout>
      <LinkOta to={nextMonthUrl}>{">>>"}</LinkOta>
    </StyledLayout>
  );
};
