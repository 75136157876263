import { FC, useState } from "react";
import { endOfToday, endOfTomorrow } from "../../common/util/date";
import { Box, Button, Calendar, Text } from "grommet";
import { Clock, Close, Trash } from "grommet-icons";
import { Modal } from "./Modal";
import { DUE_COLOR } from "../util/task";
import styled from "styled-components";

const CalendarBox = styled(Box)`
  [class*="StyledDay"] {
    width: 40px;
  }

  & > div {
    width: 350px;
  }
`;

interface ChildrenProps {
  open: () => void;
}

interface DateSelectProps {
  value?: number;
  setValue: (value: number | undefined) => void;
  children: (props: ChildrenProps) => React.ReactNode;
}

export const DateSelect: FC<DateSelectProps> = ({
  value,
  setValue,
  children,
}) => {
  const today = endOfToday().getTime();
  const tomorrow = endOfTomorrow().getTime();

  const [modal, setModal] = useState(false);
  const setAndClose = (value: number | string | undefined) => {
    if (typeof value === "string") {
      setValue(new Date(value).getTime());
    } else {
      setValue(value);
    }
    setModal(false);
  };

  const valueISO = value ? new Date(value).toISOString() : undefined;
  const nowISO = new Date().toISOString();
  const nextYearISO = new Date(
    Date.now() + 365 * 24 * 60 * 60 * 1000
  ).toISOString();

  return (
    <>
      {children({ open: () => setModal(true) })}
      {modal && (
        <Modal onClickOutside={() => setModal(false)}>
          <Box pad="small" gap="small">
            <Box direction="row" gap="large">
              <Button
                onClick={() => setAndClose(today)}
                icon={<Clock color={DUE_COLOR.TODAY} size="30px" />}
              />
              <Button
                onClick={() => setAndClose(tomorrow)}
                icon={<Clock color={DUE_COLOR.TOMORROW} />}
              />
              <Button onClick={() => setAndClose(undefined)} icon={<Trash />} />
              <div style={{ flex: "1" }} />
              <Button onClick={() => setModal(false)} icon={<Close />} />
            </Box>
            <CalendarBox>
              <Calendar
                firstDayOfWeek={1}
                bounds={[nowISO, nextYearISO]}
                activeDate="start"
                date={valueISO}
                onSelect={(day) => {
                  if (typeof day === "string") {
                    setAndClose(day);
                  }
                }}
                size="medium"
              />
            </CalendarBox>
          </Box>
        </Modal>
      )}
    </>
  );
};
