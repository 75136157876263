import { Box, Calendar } from "grommet";
import { FC } from "react";
import styled, { css } from "styled-components";
import { isSameDay } from "date-fns";

interface JikanCalendarProps {
  events: JikanEvent[];
}

export const JikanCalendar: FC<JikanCalendarProps> = ({ events }) => {
  const getDayEvents = (date: Date) => {
    return events.filter((event) => isSameDay(event.date, date));
  };

  return (
    <Calendar
      size="large"
      children={(props) => <Day {...props} events={getDayEvents(props.date)} />}
    />
  );
};

interface DayProps {
  date: Date;
  day: number;
  isInRange: boolean;
  isSelected: boolean;
  events: JikanEvent[];
}

export interface JikanEvent {
  id: string;
  date: Date;
  title: string;
  type: "quest" | "event";
}

const DayBox = styled(Box)`
  height: 120px;
  flex: 1;
  width: 100px;

  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .num {
  }
`;

// Extracted DayEvent Component
interface DayEventProps {
  event: JikanEvent;
}

const DayLabel = styled.p`
  font-size: 14px;
  color: #333;
  margin: 0;
  font-weight: bold;
`;

const StyledDavEvent = styled.div<{ event: JikanEvent }>`
  font-size: 14px;
  line-height: 1;
  ${(props) =>
    props.event.type === "event"
      ? css`
          color: blue;
        `
      : css`
          color: red;
        `};
`;

const DayEvent: FC<DayEventProps> = ({ event }) => {
  return (
    <StyledDavEvent event={event}>
      <p className="title">{event.title}</p>
    </StyledDavEvent>
  );
};

// Updated Day Component
const Day: FC<DayProps> = ({ date, day, isInRange, isSelected, events }) => {
  return (
    <DayBox border>
      <DayLabel>{day}</DayLabel>
      <div>
        {events.map((event) => (
          <DayEvent key={event.id} event={event} />
        ))}
      </div>
    </DayBox>
  );
};
