import { IChore, IEntry } from "./types";

function extractTags(input: string): { text: string; tags: string[] } {
  // Regular expression to match hashtags
  const hashtagRegex = /#\w+/g;

  // Extracting all hashtags
  const tags = input.match(hashtagRegex) || [];

  // Removing the hashtags from the original string
  const text = input.replace(hashtagRegex, "").trim();

  // Removing the hash (#) symbol from each tag
  const cleanedTags = tags.map((tag) => tag.substring(1));

  return { text, tags: cleanedTags };
}

export const choreDisplayedName = (choreName: string) => {
  const { text, tags } = extractTags(choreName);
  return text;
};

export const choreTags = (choreName: string) => {
  const { tags } = extractTags(choreName);
  return tags;
};

export enum ChoreTag {
  WORK = "work",
}

export const hasChoreTag = (choreName: string, tag: ChoreTag) => {
  return choreTags(choreName).includes(tag);
};

export const getEntryRemainingDays = (entry: IEntry) => {
  return (entry.multiDay?.of || 0) - (entry.multiDay?.num || 0);
};

export function sortChores(chores: IChore[]) {
  return [...chores]
    .sort((a, b) => {
      // sort by entries length
      if (a.entries.length > b.entries.length) {
        return -1;
      }
      if (a.entries.length < b.entries.length) {
        return 1;
      }
      const aRemainingDays = getEntryRemainingDays(a.entries[0]);
      const bRemainingDays = getEntryRemainingDays(b.entries[0]);
      // sort by remaining days
      if (aRemainingDays < bRemainingDays) {
        return -1;
      }
      if (aRemainingDays > bRemainingDays) {
        return 1;
      }
      return 0;
    })

    .sort((a, b) => {
      if (hasChoreTag(a.choreName, ChoreTag.WORK)) {
        return -1;
      }
      return 1;
    });
}
