import { Button } from "grommet";
import { ReactNode, useState } from "react";

interface CollapseBoxProps {
  children: ReactNode;
  showText?: string;
  hook: [boolean, (value: boolean) => void];
}

export const ControlledCollapseBox = (props: CollapseBoxProps) => {
  const { hook, children, showText = "Pokaż" } = props;
  const [collapsed, setCollapsed] = hook;

  if (collapsed) {
    return (
      <Button plain onClick={() => setCollapsed(false)}>
        {showText}
      </Button>
    );
  }

  return (
    <>
      <Button
        margin={{ bottom: "small" }}
        plain
        onClick={() => setCollapsed(true)}
      >
        Ukryj
      </Button>
      {children}
    </>
  );
};

export const CollapseBox = (props: Omit<CollapseBoxProps, "hook">) => {
  const collapsedHook = useState(true);

  return <ControlledCollapseBox {...props} hook={collapsedHook} />;
};
