import { Box, Button, Page, PageContent, Text } from "grommet";
import { Home, Logout } from "grommet-icons";
import React, { FC, ReactNode } from "react";
import { ALL_TAG, tagParam } from "../util/tags";
import { Panel } from "./Panel";
import { TagButton } from "./TagButton";
import { useGlobal } from "../global";
import { QuickForm } from "./QuickForm";
import { useTagUtil } from "../util/tagUtil";

import { useNavigateKen } from "../navigation";
import { CommandQueue } from "./CommandQueue";

interface PageWithControlsProps {
  children: ReactNode;
  done: number;
}

const KEN_URL = "https://guts.dziegelewski.com/ken";

export const PageWithControls: FC<PageWithControlsProps> = (props) => {
  const { doneToday, doneThisWeek } = useGlobal();
  const { children } = props;
  const { currentTag, tasks } = useGlobal();
  const navigate = useNavigateKen();
  const { getTagsByOpenCount } = useTagUtil();

  const setCurrentTag = (tag: string) => navigate(tagParam(tag));

  const setDefaults = () => {
    setCurrentTag(ALL_TAG);
  };

  const doneTodayCount = doneToday.length;

  const isIframe = window.self !== window.top;

  const topPanel = (
    <Panel position="top">
      <Box direction="row" gap={"medium"} overflow="scroll">
        {isIframe && (
          <Box
            direction="row"
            gap="medium"
            align="center"
            style={{ zIndex: 1 }}
          >
            <a href={KEN_URL} target="_blank">
              <Logout />
            </a>
          </Box>
        )}
        <div
          style={{
            border: "1px solid transparent",
            borderColor: currentTag === ALL_TAG ? "white" : "transparent",
            textAlign: "center",
          }}
        >
          <Button
            icon={<Home size="20px" />}
            onClick={setDefaults}
            style={{ margin: "0 20px -20px" }}
          />
          <Text size="small" color="black" style={{ pointerEvents: "none" }}>
            <span
              style={(() => {
                if (doneTodayCount > 0) {
                  return {
                    fontSize: `${1 + doneTodayCount / 10}em`,
                    color: "#379209",
                    fontWeight: "bold",
                  };
                }
                return {};
              })()}
            >
              ✓ {doneTodayCount}
            </span>
            {/*<span> / {doneThisWeek.length}</span>*/}
          </Text>
        </div>
        {getTagsByOpenCount().map((tag) => (
          <TagButton
            tag={tag}
            onClick={() => {
              setCurrentTag(tag.tagId);
            }}
            highlight={tag.tagId === currentTag}
          />
        ))}
      </Box>
    </Panel>
  );

  const bottomPanel = (
    <Panel position="bottom">
      <QuickForm />
    </Panel>
  );

  return (
    <Page gap="large" style={{ minHeight: "100vh" }}>
      {topPanel}
      <PageContent
        margin={{
          vertical: "40px",
        }}
        gap="medium"
      >
        {children}
      </PageContent>
      <CommandQueue />
      {bottomPanel}
    </Page>
  );
};
