import { Field, Form, Formik } from "formik";
import React, { FC, useState } from "react";
import styled from "styled-components";
import { Layout } from "../components/Layout";
import { useSettings } from "../utils/context";
import { useUpdateSettings } from "../utils/queries";
import { ISettings } from "../utils/types";

import { LinkOta } from "../navigation";
import { isFreeConclude, setFreeConclude } from "../utils/localStorage";

const StyledForm = styled(Form)`
  max-width: 600px;
`;

type FieldName = keyof ISettings;

const AUTOGEN_FIELD: FieldName = "autoGenerate";

export const SettingsPage: FC = () => {
  const settings = useSettings();
  const onChangeSettings = useUpdateSettings();

  return (
    <Layout direction="column" gap={20}>
      <p className="color bigger center">Ustawienia globalne</p>
      <Formik
        initialValues={settings}
        onSubmit={async (values) => {
          try {
            await onChangeSettings(values);
            alert("Zapisano");
          } catch (e) {
            alert("Błąd. Logi w konsoli");
            console.error(e);
          }
        }}
      >
        <Layout direction="column" gap={20}>
          <StyledForm>
            <Layout direction="column" gap={20}>
              <label>
                <Layout direction="row" gap={10}>
                  <span className="color">
                    Automatycznie generuj dla wyświetlanego dnia
                  </span>
                  <Field type="checkbox" name={AUTOGEN_FIELD} />
                </Layout>
              </label>

              <FreeConcludeButton />

              <button type="submit">Zapisz</button>
            </Layout>
          </StyledForm>
        </Layout>
      </Formik>
      <LinkOta to={"/"} link>
        Pokaż bieżący dzień
      </LinkOta>
    </Layout>
  );
};

const FreeConcludeButton = () => {
  const [show, setShow] = useState(!isFreeConclude());

  if (!show) {
    return null;
  }

  return (
    <button
      onClick={() => {
        setFreeConclude();
        setShow(false);
      }}
    >
      Free conclude
    </button>
  );
};
