import { HomePage } from "./pages/HomePage";
import { ChoresPage } from "./pages/ChoresPage";
import { PlansPage } from "./pages/PlansPage";
import { PlanOrOathAddPage } from "./pages/PlanOrOathAddPage";
import { PlanOrOathEditPage } from "./pages/PlanOrOathEditPage";
import { OathsPage } from "./pages/OathsPage";
import { SettingsPage } from "./pages/SettingsPage";
import React from "react";
import { WritePage } from "./pages/WritePage";
import { CalendarPage } from "./pages/CalendarPage";

export const otaRoutes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/:responsible/:day/*",
    element: <ChoresPage />,
  },
  {
    path: "/compact",
    element: <ChoresPage compact />,
  },
  {
    path: "/:responsible/:day/write",
    element: <WritePage />,
  },
  {
    path: "/plan",
    element: <PlansPage />,
  },
  {
    path: "/plan/add",
    element: <PlanOrOathAddPage type="plan" />,
  },
  {
    path: "/plan/edit/:id",
    element: <PlanOrOathEditPage type="plan" />,
  },
  {
    path: "/oath",
    element: <OathsPage />,
  },
  {
    path: "/oath/add",
    element: <PlanOrOathAddPage type="oath" />,
  },
  {
    path: "/oath/edit/:id",
    element: <PlanOrOathEditPage type="oath" />,
  },
  {
    path: "/calendar/",
    element: <CalendarPage />,
  },
  {
    path: "/calendar/:month",
    element: <CalendarPage />,
  },
  {
    path: "/settings",
    element: <SettingsPage />,
  },
];
