import { IChore, IMeta } from "../../utils/types";
import React, { FC, useContext, useEffect, useState } from "react";
import { ModalContext } from "../../utils/context";
import { OathWrapper } from "../OathWrapper";
import { ChoresTable } from "../ChoresTable";
import styled from "styled-components";
import { Layout } from "../Layout";
import { useUpdateMeta } from "../../utils/queries";
import { Modal } from "../Modal";
import { KcalInfo, KcalInput } from "../Kcal";
import { isFreeConclude } from "../../utils/localStorage";

const OathModalStyle = styled(Layout)`
  padding: 10px;
  max-width: 400px;
  width: 50vw;

  table {
    padding: 10px;
  }

  .seal {
    text-align: center;
    letter-spacing: 2px;
  }

  td {
    font-size: 1.3em;
  }
`;

interface ConcludeDayProps {
  enabled: boolean;
  day: string;
  meta: IMeta;
  oaths: IChore[];
}

export const ConcludeDay: FC<ConcludeDayProps> = (props) => {
  const modalContext = useContext(ModalContext);
  const [modalOpen, setModalOpen] = useState(false);
  const updateMeta = useUpdateMeta();

  const [kcal, setKcal] = useState<number | undefined>(undefined);
  const savedKcal = props.meta?.kcal;
  useEffect(() => {
    setKcal(savedKcal);
  }, [savedKcal]);

  const willSeal = !props.enabled;
  const onConclude = () => setModalOpen(true);
  const exitConclude = async () => {
    setModalOpen(false);
    // @ts-ignore
    document.activeElement?.blur();
    if (willSeal) {
      await updateMeta({ ...props.meta, concluded: true, kcal });
      navigator.vibrate(500);
    }
  };

  return (
    <>
      {props.enabled || isFreeConclude() ? (
        <button type="button" className="conclude" onClick={onConclude}>
          <span>CONCLUDE</span>
        </button>
      ) : (
        <Concluded meta={props.meta} />
      )}
      <Modal padding="0" visible={modalOpen} background="black">
        <OathWrapper>
          <OathModalStyle direction="column" gap={20}>
            <ChoresTable chores={props.oaths} />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                exitConclude();
              }}
            >
              <KcalInput kcal={kcal} setKcal={setKcal} />
            </form>
            <button type="button" onClick={exitConclude} className="seal">
              {willSeal ? "Seal it" : "Not yet"}
            </button>
          </OathModalStyle>
        </OathWrapper>
      </Modal>
    </>
  );
};

interface ConcludedProps {
  meta?: IMeta;
}

const Concluded: FC<ConcludedProps> = (props) => {
  if (!props.meta?.concluded) {
    return null;
  }
  const { kcal } = props.meta;

  return <KcalInfo kcal={kcal || 0} />;
};
