import { FC } from "react";
import { useMapTags } from "../util/tags";

interface TagsBarProps {
  tagIds: string[];
}

export const TagBar: FC<TagsBarProps> = (props) => {
  const { tagIds } = props;
  const tags = useMapTags(tagIds);

  if (!tags.length) {
    return null;
  }

  return (
    <div
      style={{
        height: "6px",
        width: "100%",
        display: "flex",
      }}
    >
      {tags.map((tag, index) => (
        <div
          key={tag.name}
          style={{
            height: "6px",
            background: tag.color,
            flex: 1,
          }}
        />
      ))}
    </div>
  );
};
