import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useContext,
} from "react";
import { CommandQueueItemDto, QuestDto, TagDto } from "./dtos";
import { useTags, useTasks } from "./util/queries";
import { taskUtil } from "./util/task";
import { useCurrentTag } from "./util/tags";
import { isToday } from "date-fns";
import { useLocalStorage } from "../common/util/react";

export const GlobalContext = createContext({
  tasks: [] as QuestDto[],
  tasksLoading: false,
  doneToday: [] as QuestDto[],
  doneThisWeek: [] as QuestDto[],
  tags: [] as TagDto[],
  activeTags: [] as TagDto[],
  lastTags: [] as TagDto[],
  search: "",
  setSearch: (search: string) => {},
  queue: [] as CommandQueueItemDto[],
  setQueue: ((q) => undefined) as Dispatch<
    React.SetStateAction<CommandQueueItemDto[]>
  >,
});

export const GlobalContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [tasks, tasksLoading] = useTasks();
  const [tags] = useTags();
  const activeTags = tags.filter((tag) => tag.active);

  const lastTagNames = taskUtil(tasks).sortByLastUpdated().collectTags(8);

  const lastTags = activeTags.filter((tag) => lastTagNames.includes(tag.name));

  const [search, setSearch] = React.useState("");

  const searchedTasks = tasks.filter((task) => {
    if (search.length < 3) {
      return true;
    }

    return task.name.toLowerCase().includes(search.toLowerCase());
  });

  const doneThisWeek = tasks.filter((task) => task.done);
  const doneToday = doneThisWeek.filter((task) => isToday(task.doneAt!));

  const [queue, setQueue] = useLocalStorage<CommandQueueItemDto[]>(
    "command-queue",
    []
  );

  return (
    <GlobalContext.Provider
      value={{
        tasks: searchedTasks,
        doneToday,
        doneThisWeek,
        tasksLoading,
        tags,
        activeTags,
        lastTags,
        search,
        setSearch,
        queue,
        setQueue,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  return {
    ...useContext(GlobalContext),
    currentTag: useCurrentTag(),
  };
};
